import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/setShop',
    name: 'SetShop',
    component: () => import('../views/setShop/index.vue')
  },
  {
    path: '/tab',
    name: 'Tab',
    component: () => import('@/views/tab.vue')
  }, {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/home/index.vue'),
  },
  {
    path: '/check',
    name: 'Check',
    component: () => import('@/views/checkOut/index.vue')
  },
  {
    path: '/comment',
    name: 'Comment',
    component: () => import('@/views/comment/index.vue')
  },
  {
    path: '/modal',
    name: 'modal',
    component: () => import('@/views/modal/index.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
