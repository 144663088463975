<template>
  <div class="cart-container">
    <div id="cartBtn" class="cart-btn iconfont icon-gouwuche cursor" v-if="!cartModal.is"
         @click="cartModal.is = cartModal.list.length > 0">
      <span>{{ cartTotal }}</span>
    </div>
    <!--购物车弹窗-->
    <div class="model cart-model" :class="{'show': cartModal.is}" @click="cartModal.is = false">
      <div class="cart-body">
        <div class="close-arrow el-icon-arrow-right cursor" @click="cartModal.is = false"></div>
        <div class="cart-content" @click.stop>
          <div class="cart-list">
            <div class="item flex" v-for="(item, index) in cartModal.list" :key="index">
              <img :src="item.imgUrl || require(`@/assets/images/cover.png`)" alt="" class="w-80 h-80 mr-10"/>
              <div class="cart-goods flex-column flex-1 row-between">
                <div>
                  <div class="name fz-16 line-clamp">{{ item.name }}</div>
                  <div class="sku color-gray fz-12 mt-5">{{ item.skuNames }}</div>
                </div>
                <div class="cart-goods-bottom flex col-center row-between">
                  <div class="price">{{ item.otPrice }}</div>
                  <div class="number-control">
                    <div class="minus el-icon-minus" @click="editCart(item, index, -1)"></div>
                    <input type="number" v-model="item.number" :min="0" :max="99" @blur="cartNumberChange(item, index)">
                    <div class="add el-icon-plus" @click="editCart(item, index, 1)"></div>
                  </div>
                </div>
              </div>
            </div>
            <div style="height: 150px"></div>
          </div>
          <div class="cart-footer flex-column row-between">
            <div class="flex row-between col-center">
              <span>已选：{{ cartTotal }}件</span>
              <span class="flex col-center">合计：<span class="price">{{ totalPrice }}</span></span>
            </div>
            <div class="flex row-between col-center">
              <span class="cursor" @click="cartModal.is = false">收起购物车<i
                class="iconfont icon-youshuangjiantou"/></span>
              <el-button type="primary" class="w-106 h-36 p-0" @click="settlement">结算</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--规格弹窗-->
    <c-model v-model="skuModal.is" width="750px" title="选择规格">
      <span slot="footer"></span>
      <div class="sku-model">
        <div class="flex">
          <img :src="skuModal.goodsItem.imgUrl || require(`@/assets/images/cover.png`)" alt="" class="wh-234 mr-17">
          <div class="sku-info flex-1">
            <div class="bold fz-16 line-clamp-2 pr-50">{{ skuModal.goodsItem.name }}</div>
            <div class="hot-labels mt-5">
              <div class="label-hot mr-10">热门</div>
              <div class="label-rec">推荐</div>
            </div>
            <div class="goods-desc color-gray fz-12 mt-10" v-if="skuModal.goodsItem.remark">
              {{ skuModal.goodsItem.remark }}
            </div>
            <div class="sku-group">
              <div class="sku-group-item mt-5" v-for="(item, index) in skuModal.list" :key="index">
                <div class="sku-group-item-name"><span class="color-red" v-if="item.isRequired == 1">*</span>{{ item.name }}：</div>
                <div class="sku-group-item-content">
                  <div class="item"
                       :class="{'seasoning-item': child.type == 1, 'active': child.active, 'disabled': child.disabled}"
                       v-for="(child, ci) in item.children"
                       :key="ci"
                       @click.stop="checkSku(child, index)">
                    <div class="sku-name">{{ child.name }}</div>
                    <div class="sku-price" v-if="child.type == 1">￥{{child.price}}</div>
                  </div>
                </div>
              </div>
              <div class="sku-group-item flex col-center mt-16">
                <div class="sku-group-item-name">选择数量：</div>
                <div class="sku-group-item-content flex">
                  <div class="number-control flex">
                    <div class="minus el-icon-minus" @click="editSkuNumber(-1)"></div>
                    <input type="number" v-model="skuModal.number" style="width: 75px" @blur="skuNumberChange()">
                    <div class="add el-icon-plus" @click="editSkuNumber(1)"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sku-footer flex col-center row-end mt-12">
          <!--<span class="flex col-center mr-20 line-price">原价：<span>{{ skuPrice.otTotalPrice }}</span></span>-->
          <span class="flex col-center mr-20">
              售价：<span class="price">{{ skuPrice.totalPrice }}</span>
            </span>
          <div class="sku-btn flex row-end">
            <el-button type="primary" :disabled="skuDisabled" id="skuAddCart" class="w-106 h-36 p-0" @click="skuAddCart">
              加入购物车
            </el-button>
            <el-button :disabled="skuDisabled" class="buy w-106 h-36 p-0" @click="buy">立即购买</el-button>
          </div>
        </div>
      </div>
    </c-model>

    <!--结算-->
    <settlement ref="settlement" @success="paySuccess"/>
  </div>
</template>
<script>
import {goodsTaste} from "@/api/goods";
import settlement from "./settlement.vue";

export default {
  name: "cart",
  components: {
    settlement
  },
  data() {
    return {
      cartModal: {
        is: false,
        list: [],
      },
      skuModal: {
        is: false,
        list: [],
        goodsItem: {},
        number: 1
      },
      moveRunning: false,
    }
  },
  computed: {
    totalPrice() {
      const money = this.cartModal.list.reduce((total, curr) => {
        return total + (curr.number * curr.price)
      }, 0)
      return Math.abs(money.toFixed(2))
    },
    cartTotal() {
      const num = this.cartModal.list.reduce((pre, curr) => {
        pre = parseInt(pre)
        curr.number = parseInt(curr.number)
        return (isNaN(pre) ? 0 : pre) + (isNaN(curr.number) ? 0 : curr.number)
      }, 0)
      return num;
    },
    skuPrice() {
      let arr = []
      this.skuModal.list.map(item => {
        if (item.children && item.children.length) {
          let check = item.children && item.children.filter(ch => !!ch.active)
          arr = arr.concat(check)
        }
      })
      this.skuModal.skuList = arr
      if (!arr.length) {
        return {
          name: '',
          price: this.skuModal.goodsItem.price,
          otPrice: this.skuModal.goodsItem.otPrice,
          otTotalPrice: (this.skuModal.goodsItem.otPrice * this.skuModal.number).toFixed(2),
          totalPrice: (this.skuModal.goodsItem.price * this.skuModal.number).toFixed(2)
        }
      }
      let addPrice = arr.reduce((pre, curr) => {
        return pre + (curr.price || 0)
      }, 0)
      let names = arr.map(item => item.name)
      let price = (this.skuModal.goodsItem.price + addPrice).toFixed(2)
      let otPrice = (this.skuModal.goodsItem.otPrice + addPrice).toFixed(2)
      let money = (price * this.skuModal.number).toFixed(2)
      let otMoney = (otPrice * this.skuModal.number).toFixed(2)
      return {
        price: price,
        otPrice: otPrice,
        totalPrice: money,
        otTotalPrice: otMoney,
        names: names.join('/')
      }
    },
    skuDisabled() {
     let seasoning = this.skuModal.list.filter(e => e.type == 1 && e.isRequired == 1)
     for (let i = 0; i < seasoning.length; i++) {
       let child = seasoning[i].children.filter(e => e.active)
       if (child.length == 0) {
         return true
       }
     }
     return false
    }
  },
  methods: {
    goodsNumber(goods) {
      const number = this.cartModal.list.reduce((pre, curr) => {
        return pre + (curr.id == goods.id ? curr.number : 0)
      }, 0)
      return number
    },
    goodsStock(goods) {
      let count = goods.stockCount - this.goodsNumber(goods)
      return count;
    },
    // 添加购物车获取规格
    addCart(item) {
      if (this.goodsStock(item) <= 0) {
        this.$message.error('商品已售罄')
        return false
      }
      if (item.specType == 0) {
        this.bindCartData(item)
        return
      }
      goodsTaste({goodsId: item.id, children: true}).then(res => {
        if (res.code == 200) {
          let list = (res.data || []).map(taste => {
            if (taste.type == 1) {
              let disableNum = 0;
              taste.children.map(s => {
                s.disabled = s.stockNumber != null && s.stockNumber <= 0
                s.active = s.isChecked == 1 && !s.disabled
                if (s.disabled) {
                  disableNum++
                }
                return s
              })
              taste.label = taste.checkNumber < 99 ? `以下${taste.children.length}选${taste.checkNumber}`: ''
              taste.isRequired = disableNum == taste.children.length ? 0: taste.isRequired
            } else {
              taste.active = false
            }
            return taste
          });
          if (list.length > 0) {
            item.sku = list
            this.skuModal = {
              is: true,
              goodsItem: item,
              list: list,
              number: 1
            }
          } else {
            this.bindCartData(item)
          }
        }
      })
    },
    checkSku(child, parentIndex) {
      let checkNumber = 0
      if (child.disabled) return
      let parent = this.skuModal.list[parentIndex]
      let list = parent.children
      if (parent.type == 1) {
        checkNumber = list.reduce((pre, curr) => {
          return pre + (curr.active ? 1 : 0)
        }, 0)
        if (!child.active && checkNumber >= parent.checkNumber) {
          this.$message.error('最多可选择' + parent.checkNumber + '个')
          return
        }
      }
      list.map(sku => {
        if (parent.type == 0) {
          if (sku.id != child.id) {
            sku.active = false
          }
        } else if (parent.type == 1) {
          if (parent.checkNumber <= 1 && !child.active) {
            sku.active = false
          }
        }
      })
      child.active = !child.active
      this.$set(this.skuModal.list, parentIndex, parent)
    },
    editSkuNumber(number) {
      let skuNumber = this.skuModal.number + number
      skuNumber = Math.max(Math.abs(skuNumber), 1)
      this.skuModal.number = Math.min(skuNumber, this.goodsStock(this.skuModal.goodsItem))
    },
    skuNumberChange() {
      let number = this.skuModal.number
      if (!number) number = 1
      let num = number + ''.split('.')
      num = !num[1] ? (num[0] + '.00') : num
      num = parseInt(num).toFixed(0)
      this.skuModal.number = Math.min(num, this.goodsStock(this.skuModal.goodsItem))
    },
    skuAddCart() {
      if (this.skuDisabled) {
        this.$message.error('请选择加料')
        return
      }
      let number = this.skuModal.number
      let skuIds = [], skuName = []
      let goodsItem = this.skuModal.goodsItem
      this.skuModal.skuList.map(ch => {
        skuIds.push(ch.id)
        skuName.push(ch.name)
      })
      skuIds = skuIds.join(',')
      skuName = skuName.join('/')
      this.bindCartData(goodsItem, this.skuModal.skuList, this.skuPrice, skuIds, skuName, number)
      this.skuModal.is = false
    },
    cartNumberChange(item, index) {
      if (!item.number) item.number = 1
      let num = item.number + ''.split('.')
      num = !num[1] ? (num[0] + '.00') : num
      item.number = parseInt(num).toFixed(0)
      item.number = Math.min(item.number, item.goodsInfo.stockCount)
      if (item.number <= 0) {
        this.editCart(item, index, 0)
      }
    },
    editCart(item, index, number) {
      item.number += number
      item.number = Math.min(item.number, item.goodsInfo.stockCount)
      if (item.number <= 0) {
        this.cartModal.list.splice(index, 1)
      }
      if (this.cartModal.list.length <= 0) {
        this.cartModal.is = false
      }
    },
    bindCartData(item, skuList = [], skuInfo = null, skuIds = '', skuNames = '', number = 1) {
      if (this.moveRunning) return
      let obj = {
        id: item.id,
        name: item.name,
        goodsInfo: item,
        price: skuInfo ? skuInfo.price : item.price,
        otPrice: skuInfo ? skuInfo.otPrice : item.otPrice,
        number: (item.number || 0),
        imgUrl: item.imgUrl,
        canCardPay: item.canCardPay,
        skuIds: skuIds,
        skuNames: skuNames,
        skuList: skuList.map(sku => {
          return {
            goodsId: sku.goodsId,
            id: sku.id,
            name: sku.name,
            goodsName: sku.goodsName,
            price: sku.price,
            type: sku.type,
            isRequired: sku.isRequired,
            parentId: sku.parentId,
            consumedNumber: sku.consumedNumber
          }
        })
      }
      let cart = this.cartModal.list.find(cart => cart.id == item.id && cart.skuIds == skuIds);
      if (cart) {
        cart.number += number
        cart.skuList = cart.skuList.concat(skuList)
        item.number += number
      } else {
        obj.number = number
        this.cartModal.list.push(obj)
        item.number = obj.number
      }
      this.cartMove(item, skuIds)
    },
    cartMove(item, skuIds) {
      let boundBtn = document.getElementById(`goods${item.id}`).getBoundingClientRect()
      let left = 0
      if (skuIds) {
        boundBtn = document.getElementById(`skuAddCart`).getBoundingClientRect()
        left = 50
      }
      let boundCart = document.getElementById('cartBtn').getBoundingClientRect()
      let flyItem = document.getElementById('flyItem')
      let flyIcon = document.getElementById('flyIcon')

      let offsetX = boundCart.left + boundCart.width / 2 - (boundBtn.left - left + boundBtn.width / 2)
      let offsetY = boundCart.top + boundCart.height / 2 - (boundBtn.top + boundBtn.height / 2)

      flyItem.style.display = 'block'
      flyItem.style.left = (boundBtn.left) + 'px'
      flyItem.style.top = (boundBtn.top) + 'px'
      this.moveRunning = true
      setTimeout(() => {
        flyItem.style.transform = 'translateX('+ offsetX +'px)';
        flyIcon.style.transform = 'translateY('+ offsetY +'px)';
        setTimeout(() => {
          flyItem.style.display = ''
          flyItem.style.transform = 'translateX(0)';
          flyIcon.style.transform = 'translateY(0)';
          this.moveRunning = false
        }, 490)
      }, 10)
    },
    buy() {
      let skuIds = []
      let skuName = []
      this.skuModal.skuList.map(ch => {
        skuIds.push(ch.id)
        skuName.push(ch.name)
      })
      skuIds = skuIds.join(',')
      skuName = skuName.join('/')
      let params = JSON.parse(JSON.stringify(this.skuModal))
      params.skuIds = skuIds
      params.skuNames = skuName
      params.price = this.skuPrice.price
      params.otPrice = this.skuPrice.otPrice
      params.totalPrice = this.skuPrice.totalPrice
      params.otTotalPrice = this.skuPrice.otTotalPrice
      this.$refs.settlement.settlementSku(params)
    },
    settlement() {
      this.$refs.settlement.settlementCart(this.cartModal)
    },
    paySuccess() {
      this.cartModal = {
        is: false,
        list: [],
      }
      this.skuModal = {
        is: false,
        list: [],
        goodsItem: {},
        number: 1
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import "cart";
</style>
