<template>
  <div class="champion-game">
    <titleHead/>
    <div class="content" v-loading="loading">
      <div class="team-list">
        <div class="team-item"
             :class="{active: currItem && currItem.id === item.id}"
             v-for="(item, index) in oddsList"
             :key="index"
             @click="selectItem(item)"
        >
          <img :src="item.icon" alt=""/>
          <div class="team-info">
            <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
              <div class="fz-16 bold">{{ item.name }}</div>
            </el-tooltip>
            <div class="fz-16 ">{{item.odds}}</div>
            <div :style="{'color': statusOptions[detail.status].color}" v-if="statusOptions[detail.status]">
              {{ statusOptions[detail.status].text }}
            </div>
          </div>
        </div>
      </div>
      <loadMore :loading="loading" :finish="finish" :empty="oddsList.length == 0"/>
    </div>
    <submitFooter :info="detail" :item="currItem" :activityType="1" :memberInfo="memberInfo" v-if="detail.id && currItem"/>
  </div>
</template>
<script>
import titleHead from "./components/titleHead.vue";
import submitFooter from "./components/submitFooter.vue";

import {guessList} from '@/api/sportsGuess'
import loadMore from "@/views/europeanCup/components/loadMore.vue";
export default {
  name: "championGame",
  props: {
    countries: {type: Array, default: () => []},
    memberInfo: {type: Object, default: () => {}},
  },
  components: {loadMore, titleHead,submitFooter},
  data() {
    return {
      loading: false,
      finish: false,
      detail: {},
      oddsList: [],
      statusOptions: {
        1: {text: '未开售', color: '#969696'},
        2: {text: '已开售', color: '#3AC260'},
        3: {text: '已封盘', color: '#FF2517'},
        4: {text: '已结算', color: '#FF2517'},
      },
      currItem: null
    }
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true
      guessList({activityType: 1}).then(res => {
        let data = res.data || []
        if (data.length) {
          this.detail = data[0]
          this.oddsList = (JSON.parse(this.detail.oddsJson) || []).map(item => {
            if (!item.icon && item.name) {
              let country = this.countries.find(country => country.cn.includes(item.name.trim()))
              item.icon = country ? country.abb2+'.png': ''
            }
            item.icon = item.icon ? require(`@/assets/images/countries/${item.icon}`): ''
            return item
          })
        }
      }).finally(() => {
        this.loading = false
        this.finish = true
      })
    },
    selectItem(item) {
      if (!this.memberInfo || !this.memberInfo.memberId) {
        this.$message.error('会员信息异常')
        return
      }
      if(this.detail.status == 2) {
        this.currItem = item
      }
    },
  },
  created() {
    this.finish = false
    this.getList()
  },
  watch: {}
}
</script>

<style scoped lang="scss">
.champion-game {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    overflow: auto;
    padding: 0 24px 0;
    border-top: 25px solid #fff;
    .team-list {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-gap: 16px;
      .team-item {
        text-align: center;
        position: relative;
        margin-top: 34px;
        cursor: pointer;
        .team-info {
          border: 1px solid #9e9e9e;
          border-radius: 8px;
          padding: 20px 0 8px;
          line-height: 26px;
        }
        &.active {
          .team-info {
            @include border_color();
            @include background_color(.1)
          }
        }
        img {
          width: 36px;
          height: 36px;
          border-radius: 100%;
          position: absolute;
          top: -20px;
          left: 50%;
          transform: translateX(-50%);
          border: 1px solid #d2d2d2;
        }
        .bold {
          max-width: 150px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
