<template>
  <span>
    <c-model v-model="show" width="726px" title="网费充值" class="recharge-model">
      <span slot="footer"></span>
      <div class="network-display">
        <div class="network-flex network-money">
          <div>网费余额∶</div>
          <div class="money-num color-red">
            {{ memberInfo.balance }}元 (现金:{{ memberInfo.availMoney }}元 赠送金:{{ memberInfo.presentedMoney }})
          </div>
        </div>
        <div class="network-flex">
          <div class="flex-label">充值金额∶</div>
          <div class="flex-display">
            <div class="flex-bnt"
                 :class="{'flex-bnt-show': index == currIndex}"
                 v-for="(item,index) in list" :key="index"
                 @click="handlePayMoneyBnt(item,index)">
              <div class="flex-bnt-item">
                <div>{{ item.conditionMoney }}元</div>
                <div class="bnt-item-text fz-12 mt-5 color-red"
                     v-if="item.presenterMoney">赠送{{ item.presenterMoney }}元</div>
              </div>
            </div>
            <div class="flex-input">
              <input type="number"
                     @focus="handlePayNumber"
                     v-model="payNumber"
                     maxlength="5"
                     placeholder="请输入金额"/>
            </div>
          </div>
        </div>
        <div class="network-flex ">
          <div class="pay-label">充值方式∶</div>
          <div class="pay-bnts flex col-center">
            <div class="wechat-add mr-12" @click="handleNetworkPayData(5)">微信</div>
            <div class="zfb-add" @click="handleNetworkPayData(6)">支付宝</div>
          </div>
        </div>
      </div>
    </c-model>
    <!--支付-->
    <scanPay v-model="scanPayModel.is"
             :title="scanPayModel.title"
             :orderNumber="scanPayModel.orderNumber"
             :qrcode="scanPayModel.imgUrl"
             @success="paySuccess"
             @fail="loading = false"
    />
  </span>
</template>
<script>
import {mapGetters} from "vuex";
import {memberBalance} from "@/api/member";
import {decryptByDESModeCBC, encryptByDESModeCBCUtf8to64} from "@/common/utils/des";
import {activityData, clientPay, clientRecharge} from "@/api/wallet";
import {numberInt} from "@/common/utils";
import scanPay from "@/components/scanPay/index.vue";

export default {
  name: "index",
  props: {
    value: true,
    memberInfo: {type: Object, default: () => {}}
  },
  components: {
    scanPay
  },
  data() {
    return {
      info: {
        balance: 0,
        availMoney: 0,
        presentedMoney: 0
      },
      list: [],
      currIndex: 0,
      payNumber: null,
      scanPayModel: {
        title: '',
        is: false,
        orderNumber: '',
        imgUrl: ''
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters(['memberId']),
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
  methods: {
    init() {
      this.loading = false
      this.getActivityList()
    },
    getActivityList() {
      let params = {
        lgUserId: this.memberInfo.memberId
      }
      activityData({data: encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if (res.code == 200) {
          this.list = res.data.refillCards
        }
      })
    },
    handlePayNumber() {
      this.currIndex = null
    },
    handlePayMoneyBnt(item, index) {
      this.currIndex = index;
      this.payNumber = null;
    },
    // 充值网费创建订单
    handleNetworkPayData(payType) {
      let payObj = {}, currIndex = this.currIndex
      if (currIndex != null) {
        payObj = this.list[currIndex]
      } else {
        let payNumber = this.payNumber
        if (numberInt(payNumber || 0)) {
          this.$message.error('请输入正整数!');
          return
        }
        if (payNumber < 10) {
          this.$message.error('充值不能少于10元!');
          return
        }
        payObj.conditionMoney = Number(payNumber)
      }
      let params = {
        memberId: this.memberInfo.memberId,
        refillCardId: payObj.cardId || null,
        refillCardType: payObj.type || null,
        topUpMoney: payObj.conditionMoney || null,
        presenterMoney: payObj.presenterMoney || null,
        type: payType,
        source: 2
      }
      // console.log(params)
      if (this.loading) return;
      this.loading = true
      clientRecharge({data: encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if (res.code == 200) {
          this.handleClientPay({
            orderNumber: res.data.orderNumber,
            payType: res.data.payType,
            orderType: 1
          })
        } else {
          this.$message.error(res.msg);
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      })
    },
    // 网费充值-支付接口
    handleClientPay(params) {
      clientPay({data: encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if (res.code == 200) {
          this.scanPayModel = {
            is: true,
            title: params.payType == 5 ? '微信支付' : '支付宝支付',
            imgUrl: res.data.imgUrl,
            orderNumber: params.orderNumber
          }
        } else {
          this.$message.error(res.msg || '操作失败!');
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      })
    },
    paySuccess() {
      this.show = this.loading = false
      this.payNumber = null;
      this.currIndex = 0
      this.$message.success('充值成功')
      this.$emit('success')
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.init()
      }
    }
  },
  created() {
    this.init()
  }
}
</script>

<style  lang="scss">
@import "index";
</style>
