<template>
  <div class="container guess-container">
    <div class="left">
      <div v-for="(item, index) in tabList"
           :key="index">
        <div class="tab-item" :class="{'active': item.type==tabIdx}"
             @click="changeTab(item.type)"
             v-if="item.show"
        >
          <div class="logo">
            <i class="iconfont" :class="item.icon" v-if="item.type == 0 || item.type == 99"/>
            <img :src="item.icon" alt="" v-else-if="item.icon">
          </div>
          <div class="tab-text">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="right">
      <!-- 记录 -->
      <recordList ref="recordList" :tabList="tabList" v-show="tabIdx == 0"/>
      <!-- 活动 -->
      <activityList ref="activityList" :memberInfo="memberInfo" :tabList="tabList" v-show="tabIdx != 0"/>
    </div>
  </div>
</template>
<script>
import {decryptByDESModeCBC, encryptByDESModeCBCUtf8to64} from "@/common/utils/des";
import {getMemberInfo} from "@/api/member";
import recordList from "./components/recordList.vue";
import activityList from "./components/activityList.vue";
export default {
  name: "index",
  components: {activityList, recordList},
  data() {
    return {
      tabList: [
        { title: "记录", icon: "icon-wj-jlb", type: 0, show: true },
        { title: "全部", icon: "icon-a-quanbu1", type: 99, show: true },
        { title: "LOL", icon: require('@/assets/images/guess/lol.png'), type: 3, show: false },
        { title: "绝地求生", icon: require('@/assets/images/guess/jdqs.png'), type: 1, show: false },
        { title: "和平精英", icon: require('@/assets/images/guess/hpjy.png'), type: 2, show: false },
        { title: "守望先锋", icon: require('@/assets/images/guess/swxf.png'), type: 4, show: false },
        { title: "LOL手游", icon: require('@/assets/images/guess/mlol.png'), type: 5, show: false },
        { title: "DOTA2", icon: require('@/assets/images/guess/dota.png'), type: 6, show: false },
        { title: "永劫无间", icon: require('@/assets/images/guess/yjwj.png'), type: 7, show: false },
        { title: "CS:GO", icon: require('@/assets/images/guess/csgo.png'), type: 8, show: false },
        { title: "世界杯", icon: '', type: 9, show: false },
        { title: "欧洲杯", icon: require('@/assets/images/guess/ozb.png'), type: 10, show: false },
      ],
      memberInfo: {},
      tabIdx: 99,
    }
  },
  methods: {
    handleUserinfo() {
      getMemberInfo().then(res => {
        if (res.code == 200) {
          this.memberInfo = JSON.parse(decryptByDESModeCBC(res.msg));
          return false;
        }
      }).catch(res=>{
      });
    },
    changeTab(type) {
      this.tabIdx = type;
      if (type == 0) {
        this.$refs.recordList.getList();
      } else {
        this.$refs.activityList.getList();
      }
    }
  },
  created() {
    this.handleUserinfo()
  }
}
</script>

<style lang="scss">
@import "index";
</style>
