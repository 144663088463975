export default {
  secret: state => state.app.secret || '',
  shop: state => state.app.shop || {},
  shopId: state => state.app.shop.shopId || '',
  shopName: state => state.app.memberInfo.shopName || '',
  memberInfo: state => state.app.memberInfo || {},
  memberId: state => state.app.memberInfo.memberId || '',
  memberNumber: state => state.app.memberInfo.memberNumber || '',
  themeName: state => state.theme.themeName,
}
