<template>
  <div class="container sigin-container">
    <div class="body" :class="themeName" v-loading="loading">
      <div class="header">
        <div class="item" v-for="(item, index) in cycleList" :key="index">
          <img src="@/assets/images/signIn/h-gift.png" alt=""
               :class="(item.status == 1 && signData.signNum >= parseInt(item.days) ? 'pointer': '')"
               v-if="item.status == 1 && signData.signNum >= parseInt(item.days)"
               @click="giveGift(item)">
          <img src="@/assets/images/signIn/h-gift-gray.png" alt="" v-else>
          <div class="con">
            <div class="name">{{ item.gift ? item.gift.name: '' }}</div>
            <div class="rule">当月累计签到{{ item.days }}天</div>
          </div>
        </div>
      </div>
      <div class="title">
        <div><img src="@/assets/images/signIn/h-gift.png" alt=""/>签到礼包</div>
        <div>
          <span>本月已签到:{{ signData.signNum }}天</span>
          <span class="rule-btn" @click="showRule = !showRule">签到规则</span>
        </div>
      </div>
      <div class="day-body">
        <div class="calendar c-day">
          <div class="item" v-for="(i,k) in beforeDays" :key="'b'+k"></div>
          <div v-for="(item,index) in dayList" :key="item.days">
            <div class="item day" :class="daySignStatus(item, index) + ' '+ themeName">
              <span>{{ item.days }}</span>
              <div class="gift-bg">
                <template v-if="item.giftList && item.giftList.length">
                  <div :class="item.giftList.length > 1 ? 'line-clamp': 'line-clamp-2'" v-for="g in item.giftList">
                    <template v-if="g.type == 4">{{g.name}}</template>
                    <template v-else>{{ g.money}}{{ awardName[g.type] }}</template>
                  </div>
                </template>
                <template v-else>无奖励</template>
              </div>
              <div class="sign-btn" @click="signIn(item, index)">{{ item.time }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--规则弹窗-->
    <div class="rule-model" :class="{show: showRule}">
      <div class="rule-body">
        <div class="head">
          <span class="iconfont icon-sijiaoxing"/> 签到规则 <span class="iconfont icon-sijiaoxing"/>
          <i class="el-icon-close" @click="showRule = false"/>
        </div>
        <div class="content">
          <p>1. 每日上机满<span class="color-red">{{ signData.signMine }}</span>分钟即可签到一次，每日<span class="color-red">0</span>点更新，每天仅限一次，不可重复签到。
          </p>
          <p>2. 签到按月份循环，每月奖励有所不同。</p>
          <p>3. 已累积签到是连续记录，循环结束不会重置累积总签到天数。</p>
          <p>4. 可跨门店签到，在电竞蜂旗下门店签到不会断签影响连续签到。</p>
          <p>5. 签到成功后奖励会自动发放到个人卡包，优惠券请在有效期之内使用。</p>
          <p>6. 上机满<span class="color-red">{{ signData.fillSignMine }}</span>分钟即可补签，每天可补签一次，补签成功会累积签到天数。</p>
          <p>7. 每月累积签到达到<span class="color-red">{{ signDays }}</span>即可领取对应礼包，当月结束后会重置礼包。</p>
        </div>
      </div>
    </div>
    <!--奖励领取弹窗-->
    <c-model v-model="showAward" width="395px" class="award-model">
      <span slot="header"/>
      <div class="award-body">
        <div class="head">
          <i class="el-icon-close award-close" @click="showAward = false"/>
        </div>
        <div class="content">
          <div class="tip fz-24">您的签到奖励已到账！</div>
          <div class="gift-card">
            <div :class="['item', `type-${item.type}`]" v-for="(item, index) in awardInfoList" :key="index">
              <div class="bg"></div>
              <div class="con">
                <div class="left">
                  <span v-if="item.type == 1"><span class="value">{{item.rate}}</span>折</span>
                  <span v-else-if="item.type == 2">充<span class="value">{{item.conditionMoney}}</span>送<span class="value">{{ item.presenterMoney }}</span></span>
                  <span v-else-if="item.type == 3">￥<span class="value">{{ item.presenterMoney }}</span></span>
                  <span v-else>{{ item.name }}</span>
                </div>
                <div class="right">{{ awardTypes[item.type] }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer"/>
    </c-model>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import {saveCycle, signIn, signInList, signRankingList} from "@/api/signIn";
import {nameStar, timingDown} from "@/common/utils";
import {getMemberInfo} from "@/api/member";
import {decryptByDESModeCBC} from "@/common/utils/des";

export default {
  name: "index",
  data() {
    return {
      memberInfo: null,
      beforeDays: 0,
      currTime: new Date().getTime(),
      signData: {
        fillSignMine: 0, // 补签时间
        fillType: 1, // 今日是否补签 1:未补签  2:已补签
        signMine: 3, // 签到时间(分钟)
        signNum: 0, // 当月累计签到次数
      },
      cycleList: [],
      dayList: [],
      weekList: ['一', '二', '三', '四', '五', '六', '日'],
      rankingList: [],
      rankingMy: {
        totalNum: 0
      },
      showRule: false,
      showAward: false,
      awardInfoList: [],
      awardName: {
        1: '饮品券',
        2: '充送券',
        3: '网费',
        4: '免费通宵券'
      },
      awardTypes: {
        1: '饮品券',
        2: '充值券',
        3: '网费',
        4: '包时卡'
      },
      loading: false,
      saveLoading: false
    };
  },
  computed: {
    ...mapGetters(['shop', 'shopId', 'themeName']),
    progress() {
      let list = JSON.parse(JSON.stringify(this.cycleList));
      list = list.sort()
      let max = 0
      for (const k in list) {
        let item = list[k]
        if (this.signData.signNum >= item.days) {
          max = k
        }
      }
      return Math.min(Math.max(max, 0) * 200, 400)
    },
    signDays() {
      let day = this.cycleList.map(item => item.days + '天')
      return day.join('、')
    }
  },
  mounted() {
    let currentDate = new Date();
    currentDate.setDate(1);
    let dayOfWeek = currentDate.getDay();
    dayOfWeek = dayOfWeek == 0 ? 7 : dayOfWeek
    this.beforeDays = dayOfWeek - 1
  },
  methods: {
    async init() {
      if (!this.shopId) {
        this.$message.error("会员信息异常!")
        return
      }
      this.loading = true
      let memberInfoRes = await getMemberInfo()
      if (!memberInfoRes || memberInfoRes.code != 200) {
        this.$message.error("会员信息异常")
        return
      }
      this.memberInfo = JSON.parse(decryptByDESModeCBC(memberInfoRes.msg))
      let signInfoRes = await signInList({lgUserId: this.memberInfo.memberId})
      if (!signInfoRes || !signInfoRes.data) {
        this.loading = false
        this.$message.error("签到活动维护中")
        return
      }
      this.loading = false
      signInfoRes = signInfoRes.data
      this.currTime = signInfoRes.currTime
      this.signData = signInfoRes

      this.cycleList = signInfoRes.cycleList.map(cycle => {
        if (cycle.giftList) {
          cycle.giftList = JSON.parse(cycle.giftList)
          cycle.giftList.map(gift => {
            if (gift.type == 1) {
              gift.rate = parseInt(gift.rate / 10)
              gift.money = gift.rate + '折'
              gift.name = gift.rate + '折饮品券'
            } else if (gift.type == 2) {
              gift.name = gift.conditionMoney + '元充送券'
              gift.money = gift.conditionMoney + '元'
            } else if (gift.type == 3) {
              gift.name = gift.presenterMoney + '元网费'
              gift.money = ''
            } else if (gift.type == 4) {
              gift.name = '免费通宵券'
              gift.money = ''
            }
          })
          cycle.gift = cycle.giftList[0] || null
        }
        return cycle
      })
      this.dayList = signInfoRes.dayList.map(day => {
        if (day.giftList) {
          day.giftList = JSON.parse(day.giftList)
          day.giftList.map(gift => {
            if (gift.type == 1) {
              gift.rate = parseInt(gift.rate / 10)
              gift.name = gift.rate + '折饮品券'
              gift.money = gift.rate + '折'
            } else if (gift.type == 2) {
              gift.name = gift.conditionMoney + '元充送券'
              gift.money = gift.conditionMoney + '元'
            } else if (gift.type == 3) {
              gift.name = gift.presenterMoney + '元网费'
              gift.money = gift.presenterMoney + '元'
            } else if (gift.type == 4) {
              gift.money = ''
            }
            return gift
          })
        }
        return day
      })
      this.dayTimeDown()
      let rankingRes = await signRankingList({lgUserId: this.memberInfo.memberId})
      if (rankingRes.data) {
        this.rankingList = rankingRes.data.list || []
        this.rankingList.map(item => {
          item.userName = nameStar(item.userName)
        })
        this.rankingMy = rankingRes.data.mySign || {totalNum: 0}
      }
    },
    dayTimeDown() {
      if (this.timing) {
        clearTimeout(this.timing)
      }
      let time = this.currTime / 1000
      for (const k in this.dayList) {
        let day = this.dayList[k]
        if (day.status == 3 || (this.signData.fillType == 1 && day.status == 4)) { // 判断倒计时
          let maxTime = day.status == 3 ? this.signData.signMine : this.signData.fillSignMine
          let diffTime = parseInt((time - this.memberInfo.onlineTime / 1000) / 60)
          if (diffTime < maxTime) {
            let signTime = parseInt(this.memberInfo.onlineTime / 1000 + maxTime * 60) * 1000
            day.time = timingDown(signTime)
            day.time = parseInt(day.time.replace(':', '')) == 0 ? '' : day.time
          } else {
            day.time = ''
          }
        } else {
          day.time = ''
        }
      }
      let isTime = this.dayList.findIndex(item => item.time && item.time != '');
      this.$forceUpdate()
      if (isTime >= 0) {
        this.timing = setTimeout(() => {
          this.dayTimeDown()
        }, 1000)
      }
    },
    daySignStatus(item) {
      if (item.time) return 'time'
      if (this.signData.fillType == 2 && item.status == 4) return 'disabled'
      return {
        1: 'disabled',
        2: 'end',
        3: 'normal',
        4: 'fill',
      }[item.status]
    },
    // 签到
    signIn(data) {
      let status = this.daySignStatus(data);
      if (['fill', 'normal'].indexOf(status) == -1) return
      let params = {
        lgUserId: this.memberInfo.memberId,
        dayId: data.dayId,
        type: status == 'fill' ? 2 : 1,
        shopId: this.shop.shopId,
        signId: this.signData.id
      }
      if (this.saveLoading) return
      this.saveLoading = true
      signIn(params).then(res => {
        if (res.code != 200) {
          this.$message.error(res.msg)
          return
        }
        this.$message.success('签到成功')
        if (data.giftList && data.giftList.length) {
          this.awardInfoList = data.giftList
          this.showAward = true
        }
        this.dayList.map(item => {
          if (item.days == data.days) {
            item.status = 2
          } else {
            if (params.type == 2 && item.status == 4) { // 补签
              item.status = 1
            }
          }
        })
        this.signData.signNum += 1
        signRankingList({lgUserId: this.memberInfo.memberId}).then(rankingRes => {
          if (rankingRes.data) {
            this.rankingList = rankingRes.data.list || []
            this.rankingList.map(item => {
              item.userName = nameStar(item.userName)
            })
            this.rankingMy = rankingRes.data.mySign || {totalNum: 0}
          }
        })
      }).finally(() => {
        this.saveLoading = false
      })
    },
    // 领取累计奖励
    giveGift(item) {
      let params = {
        lgUserId: this.memberInfo.memberId,
        shopId: this.shop.shopId,
        signId: this.signData.id,
        cycleId: item.cycleId
      }
      saveCycle(params).then(res => {
        if (res.code != 200) {
          this.$message.error(res.msg)
          return
        }
        if (item.giftList && item.giftList.length) {
          this.awardInfoList = item.giftList
          this.showAward = true
        } else {
          this.$message.error("没有可领取奖励")
        }
        item.status = 2
      })
    }
  },
  created() {
    this.init()
  },

  destroyed() {
    if (this.timing) {
      clearTimeout(this.timing)
    }
  },
}
</script>

<style lang="scss">
@import "index.scss";
</style>
