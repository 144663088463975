const state = {
  themeName: 'bright_green_theme'
}
const mutations = {
  setTheme(state, themeName) {
    state.themeName = themeName
  }
}
const actions = {
  setTheme({commit}, themeName) {
    console.log(themeName)
    commit('setTheme', themeName)
  }
}
module.exports = {
  state,
  mutations,
  actions
}
