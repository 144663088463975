<template>
  <div class="record-list">
    <titleHead :show-title="false" title="竞猜记录">
      <div class="filter" slot="right">
        <div class="lists-right flex col-center">
          <div class="mr-10">
            <el-date-picker
              v-model="dateArr"
              type="daterange"
              size="small"
              range-separator="至"
              start-placeholder="开始日期"
              value-format="yyyy-MM-dd"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <div><el-button type="primary" size="small" @click="handleSearch">查询</el-button></div>
        </div>
      </div>
    </titleHead>
    <div class="table-body mt-16">
      <div class="lists-table">
        <el-table v-loading="loading" :data="list" size="mini" height="590px">
          <el-table-column  prop="gameTypeName" width="100" :show-overflow-tooltip="true" label="赛事类型"/>
          <el-table-column align="center" prop="participation" :show-overflow-tooltip="true" label="参与项" min-width="100">
            <template slot-scope="scope" class="row">
              <span v-for="(res,idx) in scope.row.oddsList" :key="idx">
                <span v-if="res.id == scope.row.oddsId" size="mini">
                  <template v-if="res.name1">{{res.name}}VS{{res.name1}}</template>
                  <template v-else>{{res.name}}</template>; 赔率:{{res.odds}}
                </span>
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="activityName" label="参赛队伍" min-width="100"/>
          <el-table-column align="center" prop="bettingMoney" label="参与网费" min-width="80"/>
          <el-table-column align="center" prop="status" label="开奖结果" min-width="80">
            <template slot-scope="scope" class="row">
              <span :style="{color: statusOptions[scope.row.status].color}">
                {{statusOptions[scope.row.status].text}}
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="winningMoney" label="网费" width="80">
            <template slot-scope="scope" class="row">
              {{scope.row.winningMoney}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="时间" min-width="100"/>
        </el-table>
      </div>
      <div class="lists-page tc">
        <pagination
          :total="total"
          :page.sync="params.pageNum"
          :limit.sync="params.pageSize"
          @pagination="getList"
        />
      </div>
    </div>
  </div>
</template>
<script>
import titleHead from "./components/titleHead.vue";
import {betList} from '@/api/sportsGuess'
export default {
  name: "record",
  components: {titleHead},
  data() {
    return {
      dateArr: [],
      params: {
        pageNum: 1,
        pageSize: 20,
        startDate: "",
        endDate: ""
      },
      list: [],
      total: 0,
      loading: false,
      typeOptions: {
        1: '冠军游戏',
        2: '冠亚军游戏',
        3: '竞猜足球',
      },
      statusOptions: {
        1: {text: '未开奖', color: '#969696'},
        2: {text: '已中奖', color: '#FF2517'},
        3: {text: '未中奖', color: '#969696'},
        8: {text: '作废', color: '#969696'},
      },
    }
  },
  computed: {},
  methods: {
    handleSearch() {
      this.params.pageNum = 1
      this.list = []
      this.getList()
    },
    getList() {
      this.loading = true;
      let params = this.params
      if(this.dateArr && this.dateArr.length>0) {
        params.startTime = this.dateArr[0] + ' 00:00:00';
        params.endTime = this.dateArr[1]+ ' 23:59:59';
      }else{
        params.startTime = ''
        params.endTime = ''
      }
      betList(this.params).then(res => {
        this.list = (res.rows || []).map(item => {
          item.winningMoney = item.status == 8 ? item.bettingMoney : item.winningMoney;
          item.gameTypeName = this.typeOptions[item.activityType];
          item.oddsList = JSON.parse(item.oddsJson);
          return item;
        });
        this.total = res.total;
      }).finally(err => {
        this.loading = false;
      });
    }
  },
  created() {
    this.getList();
  },
  watch: {}
}
</script>

<style scoped lang="scss">
.record-list {
  position: relative;
  height: 100%;
}
</style>
