import {MEMBER_INFO, SECRET, SHOP} from '@/util/cacheKey'
import {decryptByDESModeCBC} from "@/common/utils/des";
import {getOnlineMember} from "@/api/member";
import {Message} from "element-ui";

const state = {
  secret: localStorage.getItem(SECRET) || '',
  memberInfo: JSON.parse(localStorage.getItem(MEMBER_INFO)) || {},
  shop: JSON.parse(localStorage.getItem(SHOP)) || {id: '', name: '',}
}
const mutations = {
  setSecret(state, secret) {
    state.secret = secret
    localStorage.setItem(SECRET, secret)
  },
  setShop(state, shop) {
    state.shop = shop
    localStorage.setItem(SHOP, JSON.stringify(shop))
  },
  setMemberInfo(state, memberInfo) {
    state.memberInfo = memberInfo
    localStorage.setItem(MEMBER_INFO, JSON.stringify(memberInfo))
  }
}
const actions = {
  load({commit}, secret) {
    secret = secret.replace(/\s/g, '+')
    let decSecret = decodeURIComponent(secret)
    let shop = JSON.parse(decryptByDESModeCBC(secret))
    commit('setShop', shop)
    commit('setSecret', decSecret)
    return new Promise((resolve, reject) => {
      getOnlineMember({data: secret}).then(res => {
        let memberInfo;
        if (res.code == 200) {
          memberInfo = JSON.parse(decryptByDESModeCBC(res.msg))
          shop.name = memberInfo.shopName
          commit('setShop', shop)
          commit('setMemberInfo', memberInfo)
        } else {
          Message.error('会员已下机')
        }
        resolve(memberInfo || null)
      }).catch(() => {
        reject()
      })
    })
  },
  setShop({commit}, shop) {
    commit('setShop', shop)
  },
  login({commit}, data) {
    console.log(data)
    commit('setMemberInfo', data)
  }
}

module.exports = {
  state,
  mutations,
  actions
}
