<template>
  <div class="right-list">
    <div class="lists-title flex col-center row-between">
      <div class="lists-left color-black bold">竞猜记录</div>
      <div class="lists-right flex col-center">
        <div class="mr-10">
          <el-date-picker
            v-model="dateArr"
            type="daterange"
            size="small"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div>
          <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
        </div>
      </div>
    </div>
    <div class="table-body mt-16">
      <div class="lists-table">
        <el-table v-loading="loading" :data="list" size="mini" height="598px">
          <el-table-column prop="gameTypeName" :show-overflow-tooltip="true" label="活动项目"/>
          <el-table-column prop="gameName" label="赛事名称" :show-overflow-tooltip="true"/>
          <el-table-column prop="participation" :show-overflow-tooltip="true" label="参与项" min-width="80">
            <template slot-scope="scope" class="row">
                  <span v-for="(res,idx) in scope.row.oddsShow" :key="idx">
                    <span v-if="res.id == scope.row.oddsId">{{res.name}} {{res.odds}}</span>
                  </span>
            </template>
          </el-table-column>
          <el-table-column prop="bettingMoney" label="参与网费" min-width="80"/>
          <el-table-column prop="status" label="开奖结果" min-width="80">
            <template slot-scope="scope" class="row">
                <span :class="scope.row.status==2?'statusRed':''">
                  {{scope.row.status==1?'未开奖':scope.row.status==2?'中奖':'未中奖'}}
                </span>
            </template>
          </el-table-column>
          <el-table-column prop="winningMoney" label="网费" width="80">
            <template slot-scope="scope" class="row">
                <span :class="scope.row.status==2?'statusRed':''">
                  {{scope.row.winningMoney}}
                </span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="时间" min-width="100"/>
        </el-table>
      </div>
      <div class="lists-page tc">
        <pagination
          :total="total"
          :page.sync="params.pageNum"
          :limit.sync="params.pageSize"
          @pagination="getList"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {betList} from "@/api/guess";

export default {
  name: "recordList",
  props: {
    tabList: {type: Array, default: () => ([])}
  },
  data() {
    return {
      loading: false,
      list: [],
      dateArr: [],
      params: {
        page: 1,
        pageSize: 20,
        isAsc: 1,
        name: ""
      },
      total: 0,
    }
  },
  computed: {},
  methods: {
    // 获取记录
    handleSearch() {
      this.params.pageNum = 1
      this.list = []
      this.getList()
    },
    getList() {
      let params = this.params
      if(this.dateArr && this.dateArr.length>0) {
        params.startTime = this.dateArr[0] + ' 00:00:00';
        params.endTime = this.dateArr[1]+ ' 23:59:59';
      }else{
        params.startTime = ''
        params.endTime = ''
      }
      this.loading = true
      betList(params).then(res => {
        if (res.code == 200) {
          res.rows.map(item => {
            item.oddsShow =  JSON.parse(item.oddsJson)
            let tab = this.tabList.find(tab => tab.type == item.activityType)
            item.gameTypeName = tab ? tab.title : ''
          })
          this.list = res.rows
          this.total = res.total
        }
      }).finally(()=>{
        this.loading = false
      });
    },
  },
  watch: {}
}
</script>

<style scoped lang="scss">

</style>
