<template>
  <div class="footer active">
    <div class="tips flex col-center row-between" v-if="activityType == 3">
      <div class="flex">
        <img src="@/assets/images/icon-warning.png" style="width: 15px; margin-right: 5px;"/>
        竞猜足球规则说明：得分最终结果以上下半场及其伤停补时时及其得分为准，加时赛阶段和点球大战的得分将不纳入计算范围，请孰知。祝愿您竞猜愉快，赢取丰厚奖品！
      </div>
    </div>
    <div class="form">
      <div class="time">
        <div>
          <img src="@/assets/images/europeanCup/icon_kssj.png" alt="">
          <span>{{info.startTime}}开始</span>
        </div>
        <div>
          <img src="@/assets/images/europeanCup/icon_jssj.png" alt="">
          <span>{{info.endTime}}结束</span>
        </div>
      </div>
      <div class="money-form">
        <span class="fz-16">投注网费：</span>
        <div class="input">
          <div class="prefix">￥</div>
          <div class="flex-1">
            <input type="number" placeholder="请输入投注金额" :min="0" v-model="betMoney" @change="changeInput(0)">
          </div>
          <div class="suffix">
            <div class="plus" @click="changeNumber(1)">+</div>
            <div class="minus" @click="changeNumber(-1)">-</div>
          </div>
        </div>
      </div>
      <div class="money-result">
        <div>
          <span>我的余额：<span class="color-black  changchun1hao fz-16  mr-20">{{memberInfo.balance}}元</span></span>
          <span>理论最高网费：<span class="money color-orange">{{resultMoney}}元</span></span>
        </div>
        <div class="color-gray fz-12">页面固定奖金仅供参考，请以投注时固定奖金为准。</div>
      </div>
      <el-button type="primary" size="small" :disabled="!betMoney || !canSubmit" :loading="submitLoading" @click="handleSubmit">确认</el-button>
    </div>
  </div>
</template>
<script>
import {guessBet} from '@/api/sportsGuess'
import {encryptByDESModeCBCUtf8to64} from "@/common/utils/des";
import {formatAmount} from "@/common/utils";
export default {
  name: "submitFooter",
  props: {
    info: {type: Object, default: () => {return {}}},
    item: {type: Object, default: () => {return {}}},
    activityType: {type: Number|String, default: 1},
    memberInfo: {type: Object, default: () => {}},
  },
  data() {
    return {
      betMoney: 1,
      resultMoney: 0,
      submitLoading: false,
      canSubmit: false,
    }
  },
  methods: {
    changeNumber(num) {
      if (parseFloat(this.betMoney || 0) + num < 1) {
        return
      }
      this.betMoney = formatAmount(parseFloat(this.betMoney || 0) + num)
    },
    changeInput(num = 0) {
      let reg = /^[0-9]*$/;
      if (!reg.test(this.betMoney)) {
        this.$message.error('请输入大于0的正整数');
        this.canSubmit = false
        return
      }
      this.canSubmit = true
      this.betMoney = formatAmount(this.betMoney)
      this.betMoney = Math.min(parseFloat(this.betMoney || 0) + num, this.memberInfo.balance);
      this.resultMoney = parseFloat(this.betMoney * this.item.odds).toFixed(2);
    },
    handleSubmit() {
      let reg = /^[0-9]*$/;
      if (!this.canSubmit || !reg.test(this.betMoney)) {
        return
      }
      this.$confirm('确认提交投注？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = encryptByDESModeCBCUtf8to64(JSON.stringify({
          money: this.betMoney,
          id: this.info.id,
          oddsId: this.item.id,
          memberId: this.memberInfo.memberId,
        }))
        if (this.submitLoading) return
        this.submitLoading = true
        guessBet({data: params}).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '投注成功~',
              type: 'success',
            });
            this.betMoney = 1;
            this.changeInput()
          }else{
            this.$message.error(res.msg);
          }
        }).finally(res=>{
          this.submitLoading = false
        });
      })
    }
  },
  created() {
    this.item && this.changeInput()
  },
  watch: {
    item: {
      handler(val) {
        val && this.changeInput()
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  background-color: #F4F4F4;
  position: relative;
  .form {
    height: 80px;
    padding: 0 24px;
    @include flex_center();
  }
  &.active {
    @include background_color(.1)
  }
  .time {
    font-size: 13px;
    color: #1A1A1A;
    line-height: 26px;
    div {
      @include flex_center();
    }
    img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }
  .money-form {
    flex: 1;
    color: #565656;
    margin: 0 20px;
    @include flex_center();
    .input {
      width: 200px;
      height: 39px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #E6E6E6;
      position: relative;
      padding: 0 0 0 4px;
      display: flex;
      align-items: center;
      flex: 1;
      input {
        border: none;
        outline: none;
        height: 100%;
        width: 100%;
        background: none;
        line-height: 39px;
        font-size: 14px;
        &::placeholder {
          color: #CCCCCC;
        }
      }
      .prefix {
        color: #969696;
      }
      .suffix {
        width: 30px;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        div {
          flex: 1;
          background: #F7F7F7;
          border-radius: 0px 0px 4px 0px;
          border-left: 1px solid #E6E6E6;
          @include flex_center();
          color: #969696;
          font-weight: bold;
          cursor: pointer;
          overflow: hidden;
          &:last-child {
            border-top: 1px solid #E6E6E6;
            font-size: 22px;
            line-height: 20px;
          }
        }
      }
    }
  }
  .money-result {
    margin: 0 38px 0 0;
    line-height: 26px;
    color: #565656;
    .money {
      font-family: 'changchun1hao';
      font-size: 20px;
    }
  }
  .tips {
    font-size: 11px;
    background: #2854F9;
    color: #fff;
    padding: 8px 22px;
    button {
      background: #fff;
      color: #2854F9;
      outline: none;
      border: none;
      border-radius: 2px;
      font-size: 12px;
      cursor: pointer;
    }
  }
}
</style>
