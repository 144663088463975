<template>
  <span class="coupon-container">
    <c-model v-model="show" width="616px" title="优惠券" class="my-coupon">
      <div class="list" ref="list" @scroll="scroll">
        <div class="item flex" v-for="(item, index) in list">
          <img src="@/assets/images/coupon-bg.png" alt="" class="bg">
          <div class="left tc flex flex-center">
            <div>
              <div class="money">
                <span>{{ item.title }}</span>{{ [1, 5].indexOf(item.type) >= 0 ? '元' : '折' }}
              </div>
              <div class=""></div>
            </div>
          </div>
          <div class="right flex col-center">
            <div class="pr-10">
              <div class="title flex">
                <div class="label mr-10">{{ couponType[item.type] }}</div>
                <div class="line-clamp flex-1">{{ item.name }}</div>
              </div>
              <div class="time color-gray mt-15">{{ item.startTime }} ~ {{ item.endTime }}</div>
              <div class="mt-18">{{ item.remark }}</div>
            </div>
            <div class="btn">
              <el-button type="primary" size="mini" v-if="item.canGiven == 1" @click="giving(item)">赠送</el-button>
            </div>
            <template v-if="item.type == 2 && item.isSelf == 2">
              <img src="@/assets/images/coupon/icon-giving.png" class="icon"/>
            </template>
          </div>
        </div>
        <list-status :finished="finished" :loading="loading"/>
      </div>
      <span slot="footer"></span>
    </c-model>
    <!--赠送-->
    <c-model v-model="givingShow" width="413px" class="giving-model">
      <span slot="header"></span>
      <i class="el-icon-close icon-close" @click="givingShow = false"/>
      <div class="header">
        <div>我要赠送好友</div>
        <div class="desc flex row-center mt-16">
          <div class="label mr-10">{{ couponType[form.type] }}</div>{{ form.name }}
        </div>
        <div class="input">
          <input type="text" v-model="form.toMemberNumber" placeholder="请输入好友会员号" maxlength="18">
          <el-button type="primary" size="mini" :disabled="!form.toMemberNumber.trim()" @click="confirmGive">确定</el-button>
        </div>
      </div>
      <div class="content mt-26">
        <div class="bold color-black mb-12 fz-16">常转赠人</div>
        <div class="list">
          <div class="item flex" v-for="(item,index) in oftenMemeber" :key="index">
            <div class="user-info flex-1 mr-20">
              <div class="bold fz-16 color-black">{{ item.name }}</div>
              <div class="color-gray fz-14 mt-5">{{ item.memberNumber }}</div>
            </div>
            <div>
              <el-button type="primary" size="mini" @click="givingMember(item)">转赠TA</el-button>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer"></span>
    </c-model>
    <!--确认赠送-->
    <c-model v-model="confirmShow" width="413px" class="confirm-giving-model">
      <span slot="header"></span>
      <i class="el-icon-close icon-close" @click="confirmShow = false"/>
      <div class="header">
        <div>我要赠送好友</div>
        <div class="desc flex row-center mt-16">
          <div class="label mr-10">{{ couponType[form.type] }}</div>
          {{ form.name }}
        </div>
        <div class="tc color-black mt-14 fz-16">
          {{ form.toMemberName || '' }}
          <span class="ml-10">{{ form.toMemberNumber}}</span>
        </div>
        <div class="mt-38">
          <el-button type="primary" size="small" :loading="givingLoading" @click="doGiving">确定</el-button>
        </div>
      </div>
      <span slot="footer"></span>
    </c-model>
  </span>
</template>
<script>
import {getCouponList, givenOnCoupon, givingRegular} from "@/api/wallet";
import {mapGetters} from "vuex";
import listStatus from "@/components/list-status/list-status.vue";
import {checkCardNumber, formatAmount, starCardNumber} from "@/common/utils";
import {searchMember} from "@/api/member";
import {decryptByDESModeCBC} from "@/common/utils/des";

export default {
  name: "index",
  props: {
    value: true,
  },
  components: {
    listStatus
  },
  data() {
    return {
      couponType: {
        1: '充值券',
        2: '饮品券',
        3: '权益卡',
        4: '餐食券',
        5: '上网券',
      },
      query: {
        pageNum: 1,
        pageSize: 20,
        status: 1
      },
      list: [],
      total: 0,
      loading: false,
      finished: false,

      givingShow: false,
      confirmShow: false,
      oftenMemeber: [],
      form: {
        toMemberNumber: ''
      },
      givingLoading: false
    }
  },
  computed: {
    ...mapGetters(['memberId']),
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
  mounted() {
  },
  methods: {
    starCardNumber,
    scroll(e) {
      let target = e.target;
      if (target.scrollHeight - target.offsetHeight - target.scrollTop <= 50) {
        this.getList();
      }
    },
    refresh() {
      this.query.pageNum = 1;
      this.list = []
      this.finished = this.loading = false
      this.getList()
    },
    getList() {
      this.query.memberId = this.memberId
      if (this.loading || this.finished || !this.memberId) {
        return
      }
      this.loading = true
      getCouponList(this.query).then(res => {
        res.rows.map(item => {
          item.title = item.title.replace('折', '').replace('￥', '')
          item.title = formatAmount(item.title, 2, false, false)
        })
        this.list = this.list.concat(res.rows)
        this.total = res.total
        this.finished = this.list.length >= res.total
        if (!this.finished) {
          this.query.pageNum++
        }
      }).finally(() => {
        this.loading = false
      })
    },
    getRegular() {
      if (!this.memberId) return
      givingRegular({memberId: this.memberId}).then(res => {
        this.oftenMemeber = res.data.list
      })
    },
    giving(item) {
      this.getRegular()
      this.form = {
        fromMemberId: this.memberId,
        cardType: item.cardType,
        name: item.name,
        title: item.title,
        memberCouponId: item.couponId,
        toMemberNumber: '',
        type: item.type
      }
      this.givingShow = true
    },
    givingMember(item) {
      this.form.toMemberName = item.name
      this.form.toMemberNumber = item.memberNumber
      this.confirmShow = true
    },
    confirmGive() {
      if (checkCardNumber(this.form.toMemberNumber)){
        this.$message.error('请输入正确的卡号')
        return
      }
      searchMember({memberNumber: this.form.toMemberNumber}).then(res => {
        if (res.code != 200) return
        let data = JSON.parse(decryptByDESModeCBC(res.msg))
        if (data.memberId == this.memberId) {
          this.$message.error('不能赠送自己')
          return
        }
        this.form.toMemberName = data.name
        this.confirmShow = true
      })
    },
    doGiving() {
      if (this.givingLoading) return
      this.givingLoading = true
      givenOnCoupon(this.form).then(res => {
        if (res.code != 200) return
        this.$message.success('赠送成功')
        this.givingShow = false
        this.confirmShow = false
        this.refresh()
      }).finally(() => {
        this.givingLoading = false
      })
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.refresh()
      }
    }
  },
  created() {
  }
}
</script>


<style lang="scss">
@import "index";
</style>
