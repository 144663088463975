<template>
  <div class="modal-container drag">
    <img src="@/assets/images/icon-close-w.png" class="icon-close no-drag" @click="close"/>
    <img src="@/assets/images/modal/anniversary.png" alt="" class="bg"/>
  </div>
</template>
<script>
export default {
  name: "index",
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  methods: {
    close() {
      window.ipc.send('close-modal-win')
    }
  },
  created() {
    let startTime = new Date('2024-09-13 00:00:00').getTime()
    let endTime = new Date('2024-09-27 23:59:59').getTime()
    let nowTime = new Date().getTime()
    if (startTime <= nowTime && nowTime <= endTime) {
      window.ipc.send('resize-modal-win', {width: 620, height: 650})
    }
  },
  watch: {}
}
</script>

<style scoped lang="scss">
.modal-container {
  width: 620px;
  height: 650px;
  position: relative;
  .icon-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    z-index: 100;
  }
  .bg {
    width: 100%;
    height: 100%;
  }
}
</style>
