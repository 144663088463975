<template>
    <div class="setShop">
        <div class="setShop-warp">
            <el-form class="shopForm" ref="form" :model="form" :rules="rules" label-width="100px">
                <el-form-item label="设置门店" prop="shopId">
                    <el-select v-model="form.shopId" placeholder="请选择" filterable popper-class="shopSelect" style="width:200px">
                        <el-option v-for="item in list" :key="item.id" :label="item.name" :value="parseInt(item.id)"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="shop-btn">
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </div>
        <div class="tip">
            注：设置后将不能更改，或重新安装
        </div>
    </div>
</template>
<script>
import { getShopList } from "@/api/shop";

export default {
    name: 'SetShop',
    data() {
        return {
            form: {
                shopId: null
            },
            // 表单校验
            rules: {
                shopId: [
                    { required: true, message: "请选择要绑定的门店", trigger: "blur" }
                ]
            },
            list: []
        }
    },
    methods: {
        submit(){
            this.$refs["form"].validate(valid => {
                if (valid) {
                    window.ipc.send('window-set-shop',this.form.shopId);
                }
            });
        },
        getList(){
            getShopList().then(res=>{
                if(res.code == 200){
                    this.list = res.data
                }
            })
        }
    },
    created(){
       this.getList()
    },
    mounted() {
    },
    destroyed(){
    }
}
</script>
<style lang="less">
@import "./index.less";
.shopSelect .el-select-dropdown__wrap{
    max-height: 150px !important;
    -webkit-app-region: no-drag;
}
</style>
