<template>
  <div class="no-data" v-if="!loading && finish">
    <span class="empty" v-if="empty">
      <img src="@/assets/images/europeanCup/empty.png" alt="">
      <div>非常抱歉，暂无赛事</div>
    </span>
    <span v-else>没有更多数据</span>
  </div>
</template>
<script>
export default {
  name: "loadMore",
  props: {
    empty: {type: Boolean, default: false},
    loading: {type: Boolean, default: false},
    finish: {type: Boolean, default: false},
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
  created() {
  },
  watch: {}
}
</script>

<style scoped lang="scss">
.no-data {
  .empty {
    text-align: center;
    color: #969696;
    font-size: 16px;
    margin-top: 100px;
    img {
      width: 200px;
      height: 200px;
    }
  }
}
</style>
