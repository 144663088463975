<template>
  <div class="container europeanCup">
    <div class="tabs">
      <div :class="['item', `${tab.type == activeTab? 'active' : ''}`]"
           v-for="tab in tabs"
           :key="tab.type"
           @click="activeTab = tab.type"
      >
        <i :class="['iconfont', `icon-${tab.icon}`]"/> <span>{{tab.name}}</span>
      </div>
    </div>
    <div class="body">
      <championGame :countries="countries" :memberInfo="memberInfo" v-if="activeTab == 1"/>
      <guanyaGame :countries="countries" :memberInfo="memberInfo" v-if="activeTab == 2"/>
      <soccerGame :countries="countries" :memberInfo="memberInfo" v-if="activeTab == 3"/>
      <record v-if="activeTab == 4"/>
    </div>
  </div>
</template>
<script>
import championGame from './championGame'
import guanyaGame from './guanyaGame'
import soccerGame from './soccerGame'
import record from './record'
import countries from "@/common/utils/countries";
import {getMemberInfo} from "@/api/member";
import {decryptByDESModeCBC} from "@/common/utils/des";
export default {
  name: "index",
  components: {
    championGame,
    guanyaGame,
    soccerGame,
    record
  },
  data() {
    return {
      tabs: [
        {icon: 'zuqiu', name: '竞猜足球', type: 3},
        {icon: 'guanjun', name: '冠军游戏', type: 1},
        {icon: 'guanya', name: '冠亚军游戏', type: 2},
        {icon: 'record', name: '竞猜记录', type: 4},
      ],
      activeTab: 3,
      memberInfo: {}
    }
  },
  computed: {
    countries() {return countries}
  },
  mounted() {
    this.handleUserinfo();
  },
  methods: {
    handleUserinfo() {
      getMemberInfo().then(res => {
        if (res.code == 200) {
          this.memberInfo = JSON.parse(decryptByDESModeCBC(res.msg));
          return false;
        }
      }).catch(res=>{
      });
    },
  },
  created() {
  },
  watch: {}
}
</script>

<style  lang="scss">
.europeanCup {
  display: flex;
  align-items: stretch;
  .tabs {
    height: 100%;
    padding-top: 32px;
    width: 172px;
    box-sizing: border-box;
    box-shadow: 1px 1px 8px 0 #dad8d8;
    position: relative;
    z-index: 2;
    .item {
      height: 48px;
      line-height: 48px;
      padding: 0 0 0 24px;
      border-left: 5px solid transparent;
      @include flex_center();
      justify-content: flex-start;
      color: #565656;
      cursor: pointer;
      margin-bottom: 10px;
      transition: all 0.2s ease;
      .iconfont {
        font-size: 32px;
        margin-right: 12px;
      }
      &:hover {
        @include font_color();
      }
      &.active {
        @include font_color();
        @include background_color(0.1);
        border-left: 5px solid get_color('default_color');
      }
    }
  }
  .body {
    flex: 1;
  }
}
</style>
