import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import modules from  './modules'
import getters from './getters'
export default new Vuex.Store({
  modules,
  getters
})
