<template>
  <div class="check" :class="themeName">
    <img :src="require(`@/assets/images/checkout-green_theme.png`)" alt="" class="bg">
    <i class="el-icon-close cursor no-drag" @click="checkHideData"/>
    <div style="position: relative;height: 100%">
      <div class="fz-22 bold">本次上网预计消费{{info.consumption || 0}}元</div>
      <div class="desc mt-14">祝您上网愉快，欢迎下次光临</div>
      <div class="comment-form no-drag">
        <div class="form-item" v-for="item in formSelect" :key="item.key">
          <div class="form-label">{{ item.name }}：</div>
          <div class="form-con">
            <el-radio-group v-model="form[item.key]">
              <el-radio :label="item.id" v-for="item in options" :key="item.id">{{item.label}}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <el-input type="textarea" v-model="form.content" maxlength="100" resize="none" show-word-limit placeholder="请填写您的评价（选填）"/>
      </div>
      <div class="check-flex no-drag">
        <el-button size="small" class="flex-left" @click="checkHideData">取消</el-button>
        <el-button type="primary" size="small" :loading="loading" class="flex-right" @click="checkDeplane">确认结账</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import {getMemberInfo, memberDeplane} from '@/api/member'
import {mapGetters} from "vuex";
import {decryptByDESModeCBC} from "@/common/utils/des";

export default {
  name: "Home",
  data() {
    return {
      type: "",
      info: {},
      loading: false,
      tinner: null,
      options: [
        {id: 3, label: '满意'},
        {id: 2, label: '一般'},
        {id: 1, label: '不满意'},
      ],
      formSelect: [
        {key: 'serviceType', name: '服务'},
        {key: 'hardwareType', name: '硬件'},
        {key: 'environmentType', name: '环境'},
      ],
      form: {
        serviceType: null,
        hardwareType: null,
        environmentType: null
      }
    };
  },
  computed: {
    ...mapGetters(['memberInfo', 'themeName'])
  },
  methods: {
    init() {
      this.$store.dispatch("load", this.$route.query.secret).then(() => {
        this.getInfo();
      });
    },
    getInfo() {
      if (this.tinner) {
        clearTimeout(this.tinner);
      }
      getMemberInfo().then(res => {
        if (res.code != 200) return
        this.info = JSON.parse(decryptByDESModeCBC(res.msg))
        this.tinner = setTimeout(() => {
          this.getInfo();
        }, 5000)
      })
    },
    checkHideData() {
      window.ipc.send("window-check-min");
    },
    checkDeplane() {
      window.ipc.send("member-deplane");
      window.ipc.once("member-deplane", (event, secret) => {
        if (this.loading) return
        this.loading = true
        memberDeplane({
          secret: secret,
          ...this.form
        }).then(res => {
          this.loading = false
          if (res.code == 200) {
            this.$message({
              message: "结账成功~",
              type: "success",
              customClass: "home-modal"
            });
            window.ipc.send("window-all-closed");
          } else {
            this.$message({
              message: res.msg,
              type: "error",
              customClass: "home-modal"
            });
          }
        }).finally(() => {
          this.loading = false
        });
      });
    }
  },
  created() {
    this.init()
  },
  destroyed() {
    if (this.tinner) {
      clearTimeout(this.tinner);
    }
  }
};
</script>
<style lang="scss">
.check {
  text-align: center;
  width: 350px;
  height: 350px;
  position: relative;
  -webkit-app-region: drag;
  &.green_theme {
    background: linear-gradient(360deg, rgba(50,206,121,0) 0%, #24BFB0 100%);
    .desc {
      color: #FE671D;
      font-size: 14px;
    }
  }
  &.bright_green_theme {
    background: linear-gradient(360deg, rgba(50,206,121,0) 0%, #35de27 100%);
    .desc {
      color: #FE671D;
      font-size: 14px;
    }
  }
  &.european_cup_theme {
    background: linear-gradient(360deg, rgba(40, 84, 249, 0) 0%, #2854F9 100%);
    .desc {
      color: #FE671D;
      font-size: 14px;
    }
  }
  &.red_theme {
    background: linear-gradient(360deg, rgba(255,255,255,0.03) 11%, rgba(228,16,16,0.76) 100%);
    .desc {
      @include font_color('minor_color');
      font-size: 14px;
    }
  }
  padding: 30px 20px 0;
  box-sizing: border-box;
  color: #000;
  .bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    opacity: .6;
  }
  .el-icon-close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
    font-size: 20px;
    z-index: 1;
  }

  .check-flex {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, 0);
    .flex-left {
    }
    .flex-right {
      margin-left: 20px;
    }
  }
  .comment-form {
    margin-top: 20px;
    background: #CBF1E6;
    @include background_color(1, 'common_bg');
    border-radius: 8px;
    padding: 12px;
    .form-item {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      font-size: 12px;
      .el-radio {
        font-size: 12px;
      }
      .el-radio__label {
        font-size: 12px;
      }
      .form-label {
        margin-right: 5px;
      }
      .form-con {
        flex: 1;
      }
      .el-radio-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
    textarea {
      height: 50px;
      border-radius: 4px;
      background: #fff;
      border: none;
      margin-top: 6px;
      font-size: 12px;
    }
  }
  .el-button.el-button--small {
    width: 100px;
    padding: 8px 0;
  }
}
</style>
