<template>
  <div class="head flex col-center row-center tc">
    <div class="label bold fz-18">{{ title }}</div>
    <div class="title fz-16 flex col-center" v-if="showTitle">
      <img src="@/assets/images/europeanCup/icon_zq.png" alt="" class="icon" style="width: 24px; height: 24px; margin-right: 8px;"/>
      请选择您要投注的国家/队伍
      <img src="@/assets/images/europeanCup/icon_zsx.svg" alt="" class="line"/>
    </div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "titleHead",
  props: {
    showTitle: {type: Boolean, default: true},
    title: {type: String, default: "冠军预测"}
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
  created() {
  },
  watch: {}
}
</script>

<style scoped lang="scss">
.head {
  height: 56px;
  box-sizing: border-box;
  padding-top: 24px;
  .label {
    position: absolute;
    left: 24px;
  }
  .right {
    position: absolute;
    right: 24px;
  }
  .title {
    position: relative;
    padding-bottom: 8px;
    .line {
      position: absolute;
      bottom: 0;
    }
  }
}
</style>
