<template>
  <div class="wallet-container flex container">
    <div class="left w-264">
      <div class="level-img" :class="'vip-' + memberTypes[info.memberType]" v-if="memberTypes[info.memberType]">
        <img :src="require(`@/assets/images/wallet/vip-${info.memberType}.png`)"/>
        <div class="vip-name">
          <div>姓名：{{ info.memberName }}</div>
          <div>卡号：{{ info.memberNumber }}</div>
        </div>
      </div>
      <div class="form mt-10">
        <template v-if="!memberTypes[info.memberType]">
          <div class="form-item">
            <span class="label">上网卡号:</span>
            <span>{{ info.memberNumber }}</span>
          </div>
          <div class="form-item">
            <span class="label">上网用户:</span>
            <span>{{ info.memberName }}</span>
          </div>
          <div class="form-item">
            <span class="label">会员等级:</span>
            <span>{{ info.memberType }}</span>
          </div>
        </template>
        <div class="form-item">
          <span class="label">上网区域:</span>
          <span>{{ info.areaName }}</span>
        </div>
        <div class="form-item">
          <span class="label">当前费率:</span>
          <span>{{ info.price }}元/小时</span>
        </div>
        <div class="form-item">
          <span class="label">机器编号:</span>
          <span>{{ info.deviceNumber }}</span>
        </div>
        <div class="form-item">
          <span class="label">上网时长:</span>
          <span>{{ times }}</span>
        </div>
        <div class="form-item">
          <span class="label">消费模式:</span>
          <span>
             <el-tooltip class="item" effect="dark" :content="`${info.endTime}结束`" placement="top-start"  v-if="info.entriesId">
              <span class="color-red">{{ info.entriesName }}</span>
            </el-tooltip>
            <template v-else>普通消费</template>
          </span>
        </div>
        <div class="form-item">
          <span class="label">本次消费:</span>
          <span>{{ info.consumption }}元</span>
        </div>
      </div>
      <div class="balance-money flex col-center row-between">
        <span class="fz-12">
          <span class="label">卡上余额：</span>
          <span class="fz-16 money color-black">{{ info.balance }}元</span>
        </span>
        <el-button type="primary" size="mini" @click="rechargeShow = true">充值</el-button>
      </div>
      <div class="handle-box">
        <div class="item" @click="packetTimeShow = true">
          <i class="iconfont icon-shijian1 fz-24"/>
          <div>包时</div>
        </div>
        <div class="item" @click="call()">
          <i class="iconfont icon-Group fz-24"/>
          <div>呼叫</div>
        </div>
        <div class="item" @click="checkOut">
          <i class="iconfont icon-jine fz-24"/>
          <div>结账</div>
        </div>
        <div class="item">
          <i class="iconfont icon-xiaofeijilu1" @click="consumeLogShow = true"/>
          <div class="mt-2">消费记录</div>
        </div>
        <div class="item" @click="couponShow = true">
          <i class="iconfont icon-youhuiquan3"/>
          <div class="mt-2">优惠券</div>
        </div>
      </div>
    </div>
    <div class="right flex-1">
      <div class="flex">
        <div class="slide-box flex-1">
          <el-carousel indicator-position="none">
            <el-carousel-item v-for="item in bannerList" :key="item.id">
              <c-image :url="item.imgUrl" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="ranking w-243 ml-16">
          <div class="title color-white">会员充值排行榜</div>
          <div class="ranking-list">
            <div class="head item flex">
              <div class="num">排名</div>
              <div class="name">用户名称</div>
              <div class="money">充值金额(元)</div>
            </div>
            <listScroll id="memberRanking" class="scroll-box" ref="memberRanking">
              <div :class="'item flex r'+(index+1)" v-for="(item, index) in rankingList" :key="index">
                <div class="num">
                  <span v-if="(index+1 > 3)">{{ index+1 }}</span>
                </div>
                <div class="name">{{ item.name }}</div>
                <div class="money">{{ item.money }}</div>
              </div>
            </listScroll>
          </div>
        </div>
      </div>
      <div class="right-bottom">
        <div class="wx-group">
          <!--<div class="item" v-if="info.shopWxQrcode">
            <c-image :url="info.shopWxQrcode" width="161px" height="161px"/>
            <div class="name">门店微信群</div>
          </div>-->
          <div class="item" v-for="(item, index) in qrcodeList">
            <c-image :url="item.url" width="161px" height="161px"/>
            <div class="name">{{ qrcodeTypes[item.type] }}</div>
          </div>
          <div class="item">
            <c-image :url="require('@/assets/images/wallet/dy_qrcode.png')" width="161px" height="161px"/>
            <div class="name">电竞蜂创始人抖音</div>
          </div>
        </div>
        <div class="recruit flex" v-if="bringInfo">
          <div class="content flex-1 col-center flex row-between">
            <div class="shop-info">
              <div class="fz-16">电竞蜂连锁网咖</div>
              <div class="position-desc">招聘：{{ bringInfo.positionName }}</div>
            </div>
         <!--   <div class="wx-qrcode">
              <c-image :url="require('@/assets/images/wallet/wx_qrcode.png')"
                       width="66px" height="66px"></c-image>
            </div>-->
          </div>
        </div>

      </div>
    </div>
    <!--包时-->
    <packetTime v-model="packetTimeShow"/>
    <!--消费记录-->
    <consumeLog v-model="consumeLogShow"/>
    <!--优惠券-->
    <coupon v-model="couponShow"/>
    <!--充值-->
    <recharge v-model="rechargeShow" :memberInfo="info" @success="getInfo"/>
  </div>
</template>
<script>
import {getMemberInfo} from "@/api/member";
import {call, dateDiff, dateFormat, nameStar} from "@/common/utils";
import {decryptByDESModeCBC} from "@/common/utils/des";
import listScroll from '@/components/listScroll'
import packetTime from "@/components/packetTime/index.vue";
import consumeLog from './components/consumeLog'
import coupon from "./components/coupon";
import recharge from './components/recharge'
import {bringInList, qrcodeList, slideshow} from "@/api/common";
import {rechargeRanking} from "@/api/wallet";
export default {
  name: "index",
  components: {
    listScroll,
    packetTime,
    consumeLog,
    coupon,
    recharge
  },
  data() {
    return {
      inter: null,
      times: '',
      memberTypes: {
        '白银会员': 10,
        '黄金会员': 11,
        '钻石会员': 22,
        '黑卡会员': 33,
        '至尊会员': 44,
      },
      info: {},
      bannerList: [],
      rankingList: [],
      bringInfo: null,
      bringList: [],
      qrcodeList: [],
      qrcodeTypes: {
        1: '公众号二维码',
        2: '小程序二维码',
        3: '抖音二维码'
      },
      packetTimeShow: false,
      consumeLogShow: false,
      couponShow: false,
      rechargeShow: false
    }
  },
  methods: {
    call,
    // 会员信息
    getInfo() {
      getMemberInfo().then(res => {
        if (res.code != 200) return
        this.info = JSON.parse(decryptByDESModeCBC(res.msg));
        this.info.startTime = this.info.startTime ? dateFormat(this.info.startTime, 'yyyy-MM-dd hh:mm') : ''
        this.info.endTime = this.info.endTime ? dateFormat(this.info.endTime, 'yyyy-MM-dd hh:mm') : ''
        console.log(this.info)
        let now = this.info.systemTime
        let fun = () => {
          this.times = dateDiff(new Date(this.info.onlineTime), new Date(now));
        }
        fun();
        this.inter = setInterval(() => {
          now += 1000
          fun();
        }, 1000);
      }).catch(res => {
      });
    },
    // 充值排行
    getRanking() {
      if (this.inner) {
        clearTimeout(this.inner)
      }
      rechargeRanking().then(res => {
        let old = JSON.stringify(this.rankingList)
        res.data.map(item => {
          item.name = nameStar(item.name)
        })
        this.rankingList = res.data || []
        let n = JSON.stringify(res.data)
        if (this.rankingList.length >= 20 && old != n) {
          this.$refs.memberRanking.initScroll()
        }
        this.inner = setTimeout(() => {
          this.getRanking()
        }, 10000)
      })
    },
    // 广告图
    getSlideshow() {
      slideshow({type: 10, showType: 2}).then(res => {
        if (res.code == 200) {
          this.bannerList = res.data || []
        }
      });
    },
    // 二维码
    getQrcodeList() {
      qrcodeList().then(res => {
        this.qrcodeList = res.data || []
      })
    },
    // 招聘
    getBringInList() {
      bringInList({parentId: 2, pageNum: 1, pageSize: 100}).then(res => {
        this.bringList = res.rows || []
        if (this.bringList.length) {
          this.bringInfo = {
            positionName: this.bringList.map(item => item.positionName).join('、')
          }
        }
      })
    },
    checkOut() {
      window.ipc.send('window-check-show')
    }
  },
  created() {
    this.getInfo()
    this.getSlideshow()
    this.getRanking()
    this.getBringInList()
    this.getQrcodeList()
  },
  destroyed() {
    clearInterval(this.inter)
    if (this.inner) {
      clearTimeout(this.inner)
    }
  }
}
</script>

<style lang="scss">
@import "index";
</style>
