<template>
    <div>
      <div class="right-list game-list"  v-loading="loading">
        <template v-if="list.length">
          <div :class="['game-item', `status-${item.timeStatus}`]" v-for="(item,idx) in list " :key="idx">
            <div class="game-head flex row-between">
              <div class="flex-1 fz-14">{{item.gameTypeName}}{{item.gameName}}娱乐竞猜</div>
              <div class="time flex col-center">
                <span>截止时间 <span class="ml-10">{{item.endTime}}</span></span>
                <div class="status">
                  <div>
                    <span v-if="item.timeStatus == 0"><i class="iconfont icon-shijian"/>进行中</span>
                    <span v-else><i class="iconfont icon-disable"/>已结束</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="game-info">
              <div class="game-name">
                <div>{{item.gameName}}</div>
                <div>{{item.gameTypeName}}</div>
              </div>
              <div class="team flex row color-black">
                <div class="team-vs" v-if="item.team">
                  <div>
                    <span class="mr-5">{{item.team.team1}}</span>
                    <img :src="item.team.team1Icon" alt="">
                  </div>
                  <i class="iconfont icon-vs"/>
                  <div>
                    <img :src="item.team.team2Icon" alt="">
                    <span class="ml-5">{{item.team.team2}}</span>
                  </div>
                </div>
                <div v-else>{{item.activityName}}</div>
              </div>
              <div class="right-btn tr">
                <el-button type="primary" size="small" @click="handleBetData(item)">
                  <i class="iconfont icon-jc fz-16 mr-5"/>参与竞猜
                </el-button>
              </div>
            </div>
          </div>
        </template>
        <div class="no-list" v-else>暂无投注,请耐心等待哦~</div>
      </div>

      <!--竞猜弹窗-->
      <c-model v-model="betModel.is" width="480px" class="bet-model">
        <span slot="header" class="flex col-center color-black">
          <img :src="betModel.item.gameIcon" style="width: 28px;height: 28px;margin-right: 10px;border-radius: 100%"/>
          <span class="fz-16 bold">{{betModel.item.gameTypeName}}{{betModel.item.gameName}}娱乐竞猜</span>
        </span>
        <i class="el-icon-close cursor fz-20 color-gray" @click="betModel.is = false"/>
        <div class="model-body">
          <div class="info-box">
            <div class="mb-12">
              <span class="label">赛事</span>
              <span class="">{{betModel.item.gameName}}</span>
            </div>
            <div class="flex row-between">
              <div>
                <span class="label">项目</span>
                <span class="">{{betModel.item.gameTypeName}}</span>
              </div>
              <div>
                <span class="label">截止时间</span>
                <span class="">{{betModel.item.endTime}}</span>
              </div>
            </div>
          </div>
          <div class="mt-10 mb-10 bold color-black">选择队伍</div>
          <div class="team-list">
            <div :class="['bet',
                  `bet-${index == 0 ? 0: (index+1 == betModel.item.oddsShow.length ? 2: 1)}`,
                  `${betModel.res.id == res.id ? 'active' : ''}`
                  ]"
                 v-for="(res,index) in  betModel.item.oddsShow"
                 @click="selectBet(res)"
            >
              <div>{{res.name}}</div>
              <div>{{res.odds}}倍奖励</div>
            </div>
          </div>
          <div class="flex row-between bet-result">
            <span class="color-gray">
              <span class="mr-10">我的网费</span>
              <span class="changchun1hao fz-16 color-black">{{memberInfo.presentedMoney}}</span>
            </span>
            <span class="color-gray flex col-center">
              <span class="mr-10">竞猜成功获得</span>
              <span class="changchun1hao fz-16 color-red" v-if="moneyShow > 0">
                {{moneyShow}}
                <span class="theme-color fz-12">(含参与网费)</span>
              </span>
              <span v-else>--</span>
            </span>
          </div>
          <div class="bet-form">
            <el-input-number v-model="betMoney"
                             placeholder="请输入参与数量"
                             :min="1"
                             :max="memberInfo.balance"
                             :controls="false"
                             class="tc"
                             @change="handleBetMoney"/>
            <div class="tipText fz-12 color-red mt-10" v-if="tipText">{{tipText}}</div>
          </div>
        </div>
        <span slot="footer">
          <div class="mt-28 mb-5 tc">
            <el-button type="default" size="small" @click="betModel.is = false">取消</el-button>
            <el-button type="primary" size="small" @click="handleBtnAdd">提交</el-button>
          </div>
        </span>
      </c-model>
    </div>
</template>
<script>
import {guessBet, guessList} from "@/api/guess";
import {encryptByDESModeCBCUtf8to64} from "@/common/utils/des";
import countries from "@/common/utils/countries";
export default {
  name: "activityList",
  props: {
    memberInfo: {type: Object, default: () => ({})},
    tabList: {type: Array, default: () => ([])}
  },
  data() {
    return {
      loading: false,
      list: [],
      betModel: {
        is: false,
        item: {},
        res: {}
      },
      moneyShow: 0,
      betMoney: 1,
      tipText: '',
      submitLoading: false
    }
  },
  computed: {
    countries() {return countries}
  },
  methods: {
    // 获取活动列表
    getList(activityType = '') {
      this.loading = true
      guessList({activityType: activityType}).then(res => {
        if (res.code == 200) {
          let data = res.data.sort((a, b) => new Date(a.endTime).getTime() - new Date(b.endTime).getTime())
          data.map(item => {
            item.oddsShow =  JSON.parse(item.oddsJson)
            let tab = this.tabList.find(tab => tab.type == item.activityType)
            item.gameTypeName = tab ? tab.title : ''
            let obj = {}
            item.team = null
            let activity = item.activityName.split(/vs|VS|Vs/)
            if (activity.length == 2) {
              let one = this.countries.find(country => country.cn.includes(activity[0].trim()))
              let two = this.countries.find(country => country.cn.includes(activity[1].trim()))
              if (one) {
                obj.team1 = activity[0].trim()
                obj.team1Icon = require(`@/assets/images/countries/${one.abb2}.png`)
              }

              if (two) {
                obj.team2 = activity[1].trim()
                obj.team2Icon = require(`@/assets/images/countries/${two.abb2}.png`)
              }
              if (obj.team1 && obj.team2) {
                item.team = obj
              }
            }
            item.gameIcon = tab ? tab.icon : ''
            item.teamsShow = false
            item.timeStatus = item.endTime < new Date().getTime() ? 1 : 0
          })
          this.list = res.data
        }
      }).finally(()=>{
        this.loading = false
      });
    },
    // 打开投注弹窗
    handleBetData(item) {
      this.tipText = ""
      this.betModel = {
        is: true,
        title: item.statusName,
        oddsShow: item.oddsShow,
        item: item,
        res: {}
      }
      this.moneyShow = 0
      this.betMoney = 1
    },
    selectBet(res) {
      this.betModel.res = res
      this.handleBetMoney()
    },
    // 算出投注后所得费用
    handleBetMoney() {
      let reg = /^[0-9]+.?[0-9]*$/;
      if (!this.betModel.res.id || !reg.test(this.betMoney)) {
        this.tipText = ""
        this.moneyShow = ''
        return
      }

      let odds = parseFloat(this.betModel.res.odds)
      if(this.betMoney>this.memberInfo.balance) {
        this.betMoney = this.memberInfo.balance
      }
      this.moneyShow = (this.betMoney * odds).toFixed(2)
    },
    // 提交
    handleBtnAdd() {
      var reg = /^[0-9]+.?[0-9]*$/;
      if(!this.betMoney || !reg.test(this.betMoney)) {
        this.tipText = "格式不正确,请输入数字~"
        return
      }
      if (!this.betModel.res.id) {
        this.tipText = "请选择投注队伍~"
        return
      }
      if(this.betMoney < 1) {
        this.tipText = "金额不能小于1哦~"
        return
      }
      if(this.betMoney>this.memberInfo.balance) {
        this.tipText = "金额不能大于余额哦~"
        return
      }
      let params = encryptByDESModeCBCUtf8to64(JSON.stringify({
        money: this.betMoney,
        id:this.betModel.item.id,
        oddsId: this.betModel.res.id,
      }))
      if (this.submitLoading) return
      this.submitLoading = true
      guessBet({data: params}).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '投注成功~',
            type: 'success',
          });
          this.betModel.is = false
        }else{
          this.$message.error(res.msg);
        }
      }).finally(res=>{
        this.submitLoading = false
      });

    }
  },
  watch: {},
  created() {
    this.getList()
  }
}
</script>

<style scoped lang="scss">

</style>
