<template>
  <div class="settlement-container">
    <c-model v-model="modelShow" width="590px" title="购物车结算">
      <span slot="footer"></span>
      <div class="settlement-list">
        <div class="flex th mb-5">
          <div class="item  line-clamp-1">商品</div>
          <div class="item">单价</div>
          <div class="item">数量</div>
          <div class="item">金额</div>
        </div>
        <div class="settlement-td-warp">
          <div class="flex td" v-for="(item, index) in list" :key="index">
            <div class="item line-clamp-1">
              {{ item.name }}
              <label class="taste" v-if="item.skuNames">({{ item.skuNames }})</label>
            </div>
            <div class="item">￥{{ item.otPrice }}</div>
            <div class="item">{{ item.number }}</div>
            <div class="item">￥{{ item.otTotalPrice }}</div>
          </div>
        </div>
      </div>
      <div class="sale">
        <div
          class="item flex row-between"
          :class="couponModel.currType == 1 ? 'active' : ''"
          @click="openCouponModal(1)"
        >
          <div class="lb w-48">优惠券</div>
          <div class="color-gray">
            <span class="color-red" v-if="couponModel.currType == 1 && couponModel.item">{{ couponModel.item.couponName }}</span>
            <template v-else>
              <span class="color-red" v-if="couponModel.info.couponAmount">共{{ couponModel.info.couponAmount }}个可用</span>
              <span v-else>无可用优惠券/</span>不可同活动一起使用
            </template>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div
          class="item flex row-between"
          :class="couponModel.currType == 2 ? 'active' : ''"
          @click="openCouponModal(2)"
        >
          <div class="lb w-48">活动</div>
          <div class="color-gray">
            <span class="color-red" v-if="couponModel.currType == 2 && couponModel.item">{{ couponModel.item.couponName }}</span>
            <template v-else>
              <span class="color-red" v-if="couponModel.info.shopActivityAmount">共{{
                  couponModel.info.shopActivityAmount
                }}个可用</span>
              <span v-else>无可用活动</span>/不可同优惠券一起使用
            </template>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="sale-bottom flex col-center row-end">
          <div class="mr-20 color-gray">共优惠 <span class="color-red">{{ couponModel.couponMoney }}</span> 元</div>
          <div class="total flex col-center">合计：<span class="price">{{ actualMoney }}</span></div>
        </div>
      </div>
      <div class="pay-list">
        <div class="mb-14 color-gray">支付方式</div>
        <el-radio-group v-model="payType" @change="refreshOrder">
          <div class="item" :class="{'disabled': item.type == 4 && !canCardPay}" v-for="(item, index) of payWay" :key="index">
            <el-radio :label="item.type" :disabled="item.type == 4 && !canCardPay">
              <div class="pay-label">
                <img :src="require(`@/assets/images/shop/${item.icon}.png`)" alt="">{{ item.name }}
                <span class="color-red fz-12" v-if="item.type == 4 && !canCardPay">(购物车存在不支持会员卡支付商品)</span>
              </div>
              <div class="dis-label">
                <!--<span class="dis-money" v-if="item.isDis && couponModel.discountRate">{{ couponModel.discountRate }}折优惠</span>
                <span v-else>暂无优惠</span>-->
              </div>
            </el-radio>
          </div>
        </el-radio-group>
      </div>
      <div class="sale-remark">
        <el-input
          type="text"
          placeholder="请输入备注内容"
          size="small"
          v-model="remark"
          maxlength="10"
          show-word-limit
        ></el-input>
      </div>
      <div class="payway flex row-center p-20">
        <el-button type="primary" size="small" :loading="payLoading" @click.stop="submit()">
          确认支付
        </el-button>
      </div>
    </c-model>
    <!--扫码支付-->
    <scanPay v-model="qrcodeModel.is"
             :orderNumber="qrcodeModel.orderNumber"
             :qrcode="qrcodeModel.qrcode"
             :orderType="2"
             @success="paySuccess"
             @fail="payFail"/>
    <!--卡扣-->
    <payPassword v-model="pwdModalShow" @confirm="createOrder"/>
    <!--选择优惠券-->
    <couponSelect v-model="couponModel.is"
                  :list="couponModel.list"
                  :source="couponModel.currType"
                  :selectId="(couponModel.item ? couponModel.item.couponId : null)"
                  @select="selectCoupon"/>
  </div>
</template>
<script>
import {createGoodsOrder, getCouponList, payOrder, refreshGoodsOrder} from "@/api/goods";
import {encryptByDESModeCBCUtf8to64} from "@/common/utils/des";
import scanPay from "@/components/scanPay/index.vue";
import payPassword from "@/components/payPassword/index.vue";
import couponSelect from "@/components/couponSelect/index.vue";
export default {
  name: "settlement",
  components: {
    scanPay,
    payPassword,
    couponSelect
  },
  data() {
    return {
      modelShow: false,
      list: [],
      remark: '',

      couponModel: {
        is: false,
        list: [],
        info: {},
        item: null,
        currType: null,
        couponMoney: 0
      },
      orderMoney: 0,
      actualMoney: 0,

      payWay: [
        {name: "微信支付", type: 5, icon: 'icon-wx', isDis: true},
        {name: "支付宝", type: 6, icon: 'icon-ali', isDis: true},
        {name: "会员卡支付", type: 4, icon: 'icon-card'},
      ],
      canCardPay: false,
      payType: 5,
      payLoading: false,
      qrcodeModel: {
        is: false,
        qrcode: '',
        orderNumber: ''
      },
      pwdModalShow: false
    }
  },
  computed: {
    goodsList() {
      return this.list.map(element => {
        return {
          goodsId: element.id,
          goodsNum: element.number,
          goodNum: element.number,
          goodsPrice: element.price,
          tasteIds: element.skuIds,
          canCardPay: element.canCardPay,
          skuList: element.skuList
        };
      })
    },
  },
  methods: {
    settlementSku(skuInfo) {
      this.payType = 5
      this.list = [
        {
          id: skuInfo.goodsItem.id,
          imgUrl: skuInfo.goodsItem.imgUrl,
          name: skuInfo.goodsItem.name,
          price: skuInfo.price,
          totalPrice: skuInfo.totalPrice,
          otPrice: skuInfo.otPrice,
          otTotalPrice: skuInfo.otTotalPrice,
          number: skuInfo.number,
          skuNames: skuInfo.skuNames,
          canCardPay: skuInfo.goodsItem.canCardPay,
          skuIds: skuInfo.skuIds,
          skuList: skuInfo.skuList
        }
      ]
      this.collection()
    },
    settlementCart(cartInfo) {
      this.payType = 5
      this.list = cartInfo.list.map(item => {
        item.totalPrice = (item.price * item.number).toFixed(2)
        item.otTotalPrice = (item.otPrice * item.number).toFixed(2)
        return item
      })
      this.collection()
    },
    collection() {
      let params = {
        goodsList: this.goodsList,
        payType: this.payType,
        // version: '1.0.0'
      };
      getCouponList(params).then(res => {
        if (res.code != 200) {
          this.$message.error(res.msg);
          return false
        }
        this.modelShow = true;
        this.payLoading = false
        this.orderMoney = res.data.orderMoney
        this.actualMoney = res.data.actualMoney
        this.canCardPay = res.data.cardPay == 1
        this.couponModel = {
          is: false,
          info: res.data,
          item: null,
          currType: null,
          couponMoney: res.data.discounts,
          discountRate: parseFloat(res.data.discountRate)*10
        }
        this.remark = "";
      });
    },
    openCouponModal(type) {
      if (type == 1) {
        this.couponModel.list = this.couponModel.info.couponList;
        if (!this.couponModel.info.couponList.length) return
      } else {
        this.couponModel.list = this.couponModel.info.shopActivityList;
        if (!this.couponModel.info.shopActivityList.length) return
      }
      if (this.couponModel.currType != type) {
        this.couponModel.item = null
      }
      this.couponModel.currType = type
      this.couponModel.is = true
    },
    selectCoupon(item) {
      this.couponModel.item = item
      if (!item) {
        this.couponModel.couponMoney = 0
        this.couponModel.currType = null
      }
      this.refreshOrder()
    },
    refreshOrder() {
      let params = {
        orderMoney: this.orderMoney,
        infoDTOList: this.goodsList,
        payType: this.payType,
        // version: '1.0.0'
      }
      if (this.couponModel.item) {
        params.couponId = this.couponModel.item.couponId;
        params.couponType = this.couponModel.currType;
      }
      refreshGoodsOrder(params).then(res => {
        if (res.code != 200) {
          this.$message.error(res.msg || '获取订单信息失败');
          return
        }
        this.orderMoney = res.data.orderMoney;
        this.actualMoney = res.data.actualMoney;
        this.couponModel.couponMoney = res.data.discountMoney;
        this.totalPrice = res.data.actualMoney;
      });
    },

    submit() {
      if (this.payType == 4) {
        this.pwdModalShow = true;
      } else {
        this.createOrder();
      }
    },
    createOrder(password) {
      if (this.payLoading) return
      this.payLoading = true
      let params = {
        payType: this.payType,
        orderMoney: this.orderMoney,
        actualMoney: this.actualMoney, // 实付金额
        discountMoney: this.couponModel.couponMoney, // 优惠金额
        remark: this.remark || "",
        infoDTOList: this.goodsList,
        // version: '1.0.0'
      }
      if (this.couponModel.item) {
        params.couponType = this.couponModel.currType;
        params.couponId = this.couponModel.item.couponId;
      }
      if (this.payType == 4) {
        params.password = password;
      }
      console.log(params)
      createGoodsOrder({
        data: encryptByDESModeCBCUtf8to64(JSON.stringify(params))
      }).then(res => {
        if (res.code == 200) {
          this.payOrder({
            payType: this.payType,
            orderNumber: res.data.orderNumber,
            orderMoney: this.totalPrice
          })
          return false;
        }
        this.payLoading = false
        this.$message.error(res.msg);
      }).catch(() => {
        this.payLoading = false
      });
    },
    payOrder(params) {
      payOrder({
        data: encryptByDESModeCBCUtf8to64(JSON.stringify(params))
      }).then(res => {
        if (res.code == 200) {
          if (params.payType == 4) {
            this.paySuccess();
          } else {
            this.qrcodeModel = {
              is: true,
              qrcode: res.data.imgUrl,
              orderNumber: params.orderNumber
            };
          }
          return false;
        }
        this.$message.error(res.msg);
        this.payLoading = false;
      });
    },
    paySuccess() {
      this.$message.success("支付成功");
      this.modelShow = false;
      this.payLoading = false;
      this.qrcodeModel.is = false
      this.$emit('success')
    },
    payFail() {
      this.payLoading = false;
    }
  }
}
</script>

<style  lang="scss">
@import "settlement.scss";
</style>
