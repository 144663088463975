<template>
  <div class="soccer-game">
    <titleHead :showTitle="false" title="竞猜足球"/>
    <div class="content">
      <div class="team-list">
        <div class="group" v-for="(item, index) in list" :key="index">
          <div class="title">
            <span class="mr-10">{{ index }}</span>
            <span>共<span class="number">{{item.length}}</span>场比赛</span>
          </div>
          <div class="group-item" v-for="(child, childIndex) in item" :key="childIndex">
            <div class="game-info">
              <div class="time flex">
                <img src="@/assets/images/europeanCup/icon_time.png" alt="">
                <div>
                  {{ child.gameName}}
                  <div class="flex col-center">
                    <span>{{ child.showOpenTime }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="team-info">
              <div class="item"
                   :class="{active: currItem && currItem.id == odd.id}"
                   v-for="(odd, oddIndex) in child.oddList"
                   :key="oddIndex"
                   @click="selectItem(child, odd)">
                <div class="team-logo">
                  <template v-if="odd.icon">
                    <img :src="odd.icon" alt="">
                    <el-tooltip class="item" effect="dark" :content="odd.name" placement="top-start">
                      <div>{{ odd.name }}</div>
                    </el-tooltip>
                  </template>
                  <template v-else>
                    <span class="iconfont icon-vs"/>
                  </template>
                </div>
                <div class="bet">
                  <div>{{ odd.name == '平局'? '平局': odd.name+'胜' }}</div>
                  <div>1:{{ odd.odds }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loadMore :loading="loading" :finish="finish" :empty="list.length == 0"/>
    </div>
    <submitFooter :info="detail" :item="currItem" :activityType="3" :memberInfo="memberInfo" v-if="detail.id && currItem"/>
  </div>
</template>
<script>
import titleHead from "@/views/europeanCup/components/titleHead.vue";
import submitFooter from "@/views/europeanCup/components/submitFooter.vue";
import loadMore from "./components/loadMore.vue";
import {guessList} from "@/api/sportsGuess";
import {dateFormat} from "@/common/utils";

export default {
  name: "soccerGame",
  props: {
    countries: {type: Array, default: () => []},
    memberInfo: {type: Object, default: () => {}},
  },
  components: {submitFooter, titleHead, loadMore},
  data() {
    return {
      loading: false,
      finish: false,
      list: [],
      detail: {},
      statusOptions: {
        1: {text: '未开售', color: '#969696'},
        2: {text: '已开售', color: '#3AC260'},
        3: {text: '已封盘', color: '#FF2517'},
        4: {text: '已结算', color: '#FF2517'},
      },
      currItem: null
    }
  },
  methods: {
    getList() {
      this.loading = true
      guessList({activityType: 3}).then(res => {
        let data = res.data || []
        if (data.length) {
          this.list = data.map(item => {
            item.date = dateFormat(new Date(item.openTime), 'yyyy年MM月dd日');
            item.showOpenTime = dateFormat(new Date(item.openTime), 'yyyy年MM月dd日 hh:mm');
            item.oddList = (JSON.parse(item.oddsJson) || []).map(odd => {
              if (!odd.icon && odd.name) {
                let country = this.countries.find(country => country.cn.includes(odd.name.trim()))
                odd.icon = country ? country.abb2+'.png': ''
              }
              odd.icon = odd.icon ? require(`@/assets/images/countries/${odd.icon}`): ''
              return odd
            })
            return item
          }).sort((a, b) => {
            return new Date(a.openTime).getTime() - new Date(b.openTime).getTime()
          }).reduce((result, item) => {
            (result[item.date] = result[item.date] || []).push(item);
            return result;
          }, {});
        }
      }).finally(() => {
        this.finish = true
        this.loading = false
      })
    },
    selectItem(item, odd) {
      if (!this.memberInfo || !this.memberInfo.memberId) {
        this.$message.error('会员信息异常')
        return
      }
      this.detail = item
      if(this.detail.status == 2) {
        this.currItem = odd
      }
    },
  },
  created() {
    this.finish = false
    this.getList()
  },
  watch: {}
}
</script>


<style scoped lang="scss">
.soccer-game {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    overflow: auto;
    padding: 0 24px 0;
    border-top: 25px solid #fff;
    .team-list {
      .group {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .title {
          border-bottom: 1px solid #E6E6E6;
          padding-bottom: 6px;
          .number {
            color: $-color-orange;
            margin: 0 4px;
            font-weight: bold;
            font-size: 20px;
          }
        }
        .group-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 0;
          border-bottom: 1px solid #F4F4F4;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          .time {
            background: #F7F7F7;
            height: 52px;
            width: 252px;
            color: #565656;
            @include flex_center();
            img {
              width: 24px;
              height: 24px;
              margin-right: 12px;
            }
          }
          .team-info {
            margin-right: 120px;
            @include flex_center();
            img {
              width: 40px;
              height: 40px;
              border-radius: 100%;
            }
            .team-logo {
              height: 40px;
              margin-bottom: 10px;
              @include flex_center();
              .iconfont {
                font-size: 20px;
                color: #000000;
              }
              div {
                margin-left: 16px;
                font-weight: bold;
                font-size: 18px;
                color: #000000;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
            .bet {
              background-size: 100% 100%;
              height: 40px;
              width: 173px;
              @include flex_center();
              flex-direction: column;
              div:first-child {
                color: #565656;
                width: 80%;
                font-size: 12px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                margin: 0 auto;
                text-align: center;
              }
              div:last-child {
                font-size: 14px;
                font-family: 'changchun1hao';
                color: #000000;
              }
            }
            .item {
              cursor: pointer;
              margin-left: -20px;
              max-width: 173px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              &.active div{
                @include font_color();
              }
              &:nth-child(1) {
                .bet {
                  background-image: url("~@/assets/images/europeanCup/bet1.png");
                }
                &.active {
                  .bet {
                    background-image: url("~@/assets/images/europeanCup/bet1-active.png");
                  }
                }
              }
              &:nth-child(2) {
                .bet {
                  background-image: url("~@/assets/images/europeanCup/bet2.png");
                }
                &.active {
                  .bet {
                    background-image: url("~@/assets/images/europeanCup/bet2-active.png");
                  }
                }
              }
              &:nth-child(3) {
                .bet {
                  background-image: url("~@/assets/images/europeanCup/bet3.png");
                }
                &.active {
                  .bet {
                    background-image: url("~@/assets/images/europeanCup/bet3-active.png");
                  }
                }
              }
            }

          }

        }
      }
    }
  }
}
</style>
