<template>
  <div class="shop-container container">
    <div class="flex row-between col-center mb-18">
      <div class="category-list flex col-center">
        <div class="item cursor"
             :class="{'active': currParentIndex == index}"
             v-for="(item, index) in categoryList"
             :key="item.id"
             @click="checkCategory(index)"
        >{{item.name}}</div>
      </div>
      <div class="search flex col-center">
        <el-input v-model="search.params.name"
                  size="small"
                  prefix-icon="el-icon-search"
                  clearable
                  placeholder="请输入关键字"
                  @clear="refreshGoodsList"
                  @keyup.enter.native="refreshGoodsList" />
        <el-button type="primary" size="mini" @click="goodSearch">搜索</el-button>
      </div>
    </div>
    <div class="goods-content pt-6" v-loading="goodsListLoading">
      <div class="sub-category-list flex col-center pl-16 pr-16" v-if="subCategoryList.length">
        <div class="item cursor"
             :class="{'active': currSubIndex == index}"
             v-for="(item, index) in subCategoryList"
             :key="item.id"
             @click="checkSubCategory(index)"
        >{{item.name}}</div>
      </div>
      <div class="goods-box" ref="goodsList" @scroll="goodsListScroll">
        <div class="goods-list">
          <div class="item flex-column" v-for="(item, index) in search.list" :key="index">
            <div class="image" :class="{'stock-none': item.stockCount != null && item.stockCount <= 0}">
              <c-image :url="item.imgUrl"/>
            </div>
            <div class="goods-info flex-1 flex-column row-between">
              <div>
                <div class="line-clamp">{{item.name}}</div>
                <div class="hot-labels">
                  <div class="label-hot mt-5 mr-10" v-if="item.isHot">热门</div>
                  <div class="label-rec mt-5" v-if="item.isRecommend">推荐</div>
                </div>
              </div>
              <div class="goods-bottom flex row-between">
                <div class="flex flex-1 col-base">
                  <span class="price">{{item.price}}</span>
                  <!--<span class="text-line line-price">{{item.otPrice}}</span>-->
                </div>
                <div :id="'goods'+item.id" class="add-cart iconfont icon-gouwuche" v-if="item.stockCount != 0" @click="checkSku(item, index, $event)"/>
              </div>
            </div>
          </div>
        </div>
        <div class="no-data" v-if="!goodsListLoading">
          <template v-if="finished && search.list.length == 0">暂无商品</template>
          <template v-if="finished && search.list.length > 0">没有更多了</template>
        </div>
      </div>

    </div>
    <div id="flyItem" class="fly-item">
      <div id="flyIcon" class="add-cart iconfont icon-gouwuche"></div>
    </div>
    <cart ref="cart" v-model="cartShow"/>
  </div>
</template>
<script>
import {getCategoryList, getGoodsList, getSubCategoryList} from "@/api/goods";
import {mapGetters} from "vuex";
import cart from './components/cart.vue'

export default {
  name: "Shop",
  components: {
    cart
  },
  data() {
    return {
      bannerList: [],
      info: {},
      cartShow: false,

      categoryList: [],
      subCategoryList: [],
      currParentIndex: 0,
      currSubIndex: 0,

      goodsListLoading: false,
      finished: false,
      search: {
        params: {
          pageNum: 1,
          pageSize: 20,
          isAsc: 1,
          isHot: null,
          isNew: null,
          isRecommend: null,
          name: ""
        },
        total: 0,
        list: []
      },
    };
  },
  computed: {
    ...mapGetters(["shopId", "shopName"]),
    parentCategory() {
      return this.categoryList[this.currParentIndex];
    },
    subCategory() {
      return this.subCategoryList[this.currSubIndex];
    }
  },
  methods: {
    initData() {
      this.getCategoryList();
    },
    getCategoryList() {
      getCategoryList().then(res => {
        if (res.code == 200) {
          let list = res.data;
          this.categoryList = list;
          this.categoryList.unshift(
              {id: -1, name: '热门', key: 'isHot'},
              {id: -2, name: '新品', key: 'isNew'},
              {id: -3, name: '推荐', key: 'isRecommend'},
          )
          if (list.length > 0) {
            this.checkCategory(0)
          }
        }
      });
    },
    orderGoods() {
      this.search.params.isAsc = this.search.params.isAsc == 1 ? 2 : 1;
      this.getGoodsList();
    },
    goodSearch() {
      this.refreshGoodsList();
    },
    checkCategory(index) {
      this.currParentIndex = index;
      this.currSubIndex = 0;
      this.search.params.isHot = this.search.params.isNew = this.search.params.isRecommend = null;
      this.subCategoryList = []
      if (this.parentCategory.id < 0) {
        this.search.params[this.parentCategory.key] = 1;
        this.search.params.secondType = this.search.params.firstType = null;
        this.refreshGoodsList()
        return
      }
      this.search.params.firstType = this.parentCategory.id;
      this.getSubCategoryList(this.parentCategory.id);
    },
    getSubCategoryList(categoryId) {
      getSubCategoryList({
        id: categoryId,
        page: 1,
        pageSize: 100
      }).then(res => {
        if (res.code == 200) {
          var list = res.data;
          this.subCategoryList = list;
          if (list.length > 0) {
            this.search.params.secondType = this.subCategory.id;
            this.refreshGoodsList();
          } else {
            this.search.list = [];
          }
        }
      });
    },
    checkSubCategory(index) {
      this.currSubIndex = index;
      this.search.params.secondType = this.subCategory.id;
      this.refreshGoodsList();
    },
    refreshGoodsList() {
      this.search.params.pageNum = 1;
      this.search.list = []
      this.finished = false
      this.getGoodsList();
    },
    goodsListScroll(e) {
      let target = e.target;
      if (target.scrollHeight - target.offsetHeight - target.scrollTop <= 50) {
        this.getGoodsList();
      }
    },
    getGoodsList() {
      if (this.goodsListLoading || this.finished) return
      this.goodsListLoading = true
      getGoodsList(this.search.params).then(res => {
        if (res.code == 200) {
          this.search.list = this.search.list.concat((res.rows || []).map(goods => {
            goods.stockCount = goods.stockCount == null ? 999: goods.stockCount
            return goods
          }));
          this.search.total = res.total;
          this.finished = this.search.list.length >= res.total
          if (!this.finished) {
            this.search.params.pageNum++;
          }
        }
      }).finally(() => {
        this.goodsListLoading = false;
      });
    },
    // 加入购物车
    checkSku(item, index) {
      this.$refs.cart.addCart(item)
    },
  },
  created() {

  },
  mounted() {
    this.initData();
  },
  destroyed() {

  }
};
</script>

<style lang="scss">
@import "index.scss";
</style>
